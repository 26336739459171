import { useQuery } from '@tanstack/react-query'
import { getSdk, User } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { useQueryTime } from '@/hooks/useQueryTime'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'

export const useAllUsers = () => {
  const {
    isLoading: loadingAllUsers,
    data: allUsers,
    status,
  } = useQuery({
    queryKey: ['ALL_USERS'],
    queryFn: async () =>
      getSdk(graphQLClient)
        .GetAllUsers()
        .then((result) => result.getAllUsers as User[])
        .catch((err) => {
          const message = err?.message ?? 'Error on GetAllUsers'
          createNotification('Error on GetAllUsers', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    placeholderData: [],
  })
  useQueryTime(`['ALL_USERS'}]`, status)
  return {
    loadingAllUsers,
    allUsers: allUsers ?? [],
  }
}
