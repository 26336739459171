import { useQuery } from '@tanstack/react-query'
import { getSdk, TraderBotsQueryVariables } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { useQueryTime } from '@/hooks/useQueryTime'

export const useTraderBots = (params?: TraderBotsQueryVariables) => {
  const {
    isLoading: loadingTraderBots,
    data: traderBots,
    status,
  } = useQuery({
    queryKey: ['TRADER_BOTS'],
    queryFn: async () =>
      getSdk(graphQLClient)
        .TraderBots(params)
        .then((result) => result.traderBots)
        .catch((err) => {
          const message = err?.message ?? 'Error on TraderBots'
          createNotification('Error on TraderBots', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    placeholderData: [],
  })
  useQueryTime(`['TRADER_BOTS'}]`, status)
  return {
    loadingTraderBots,
    traderBots: traderBots ?? [],
  }
}
