import { Navigate, useLocation } from 'react-router-dom'
import { useUser } from '@/hooks/useUser'
import { isUserAdmin } from '@/types/Role'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'

export const AdministrationPermission = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation()
  const { user } = useUser()
  const hasPermission = user ? isUserAdmin(user) : false

  if (!hasPermission && pathname === '/administration') {
    createNotification(
      'Error on AdministrationPermission',
      'You do not have permission to access administration page',
      NotificationType.SIMPLE,
      NotificationStatus.ERROR,
    )
    return <Navigate to='..' />
  }

  return children
}
