import { useEffect } from 'react'
import { wsClient } from '@/services/graphql'
import { useFirebaseAuth } from '@/utils/firebaseAuthProvider'
import { UserBalances } from '@/graphql/generated/graphql-request'
import { USER_BALANCES } from '@/services/graphql/subscriptions/user'
import { queryClient } from '@/services/api'

export const useUserBalanceSubscription = () => {
  const user = useFirebaseAuth()
  useEffect(() => {
    const subscription = wsClient.subscribe(
      {
        variables: {
          userUid: user?.uid ?? ' ',
        },
        operationName: 'UserBalancesUpdated',
        query: USER_BALANCES,
      },
      {
        next: ({ data }: { data: { userBalancesUpdated: UserBalances } | null }) => {
          if (!data) return
          if (data.userBalancesUpdated) {
            queryClient.setQueryData(['USER_BALANCES'], data.userBalancesUpdated)
            console.log('useUserBalanceSubscription - Received data', data?.userBalancesUpdated)
          }
        },
        error: (err) => {
          console.log('useUserBalanceSubscription subscription error', err)
        },
        complete: () => {},
      },
    )

    // Cleanup function to unsubscribe
    return () => {
      console.log('useUserBalanceSubscription - Unsubscribing from balances subscription')
      subscription()
    }
  }, [user?.uid])
}
