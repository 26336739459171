import { CreateLiquidityPoolForm } from '@/components/administration/CreateLiquidityPoolForm'
import { TableAdministrationPools } from '@/components/administration/TableAdministrationPools'
import { tableColumnsFunds } from '@/components/administration/TableColumnsPools'
import { Separator } from '@/components/ui/separator'
import { usePools } from '@/hooks/usePools'
import { AddRemoveUserBalance } from '@/components/administration/AddRemoveUserBalance'
import { CreateAssetForm } from '@/components/administration/CreateAssetForm'
import { TableUserRoles } from '@/components/administration/TableUserRoles'
import { SupplyPanel } from '@/components/administration/SupplyPanel'
import { CollateralizePanel } from '@/components/administration/CollateralizePanel'
import { WithdrawPanel } from '@/components/administration/WithdrawPanel'
import { RepayPanel } from '@/components/administration/RepayPanel'
import { CandlesPanel } from '@/components/administration/candlesPanel'
import { OrderBots } from '@/components/administration/OrderBots'
import { TraderBots } from '@/components/administration/TraderBots'
import { TableModulePositions } from '@/components/administration/TableModulePositions'
import { TableAssets } from '@/components/administration/TableAssets'
import { useState } from 'react'

const ADMIN_TABS = {
  USERS: 'Users',
  BOTS: 'Bots',
  ASSET: 'Asset',
  POOLS: 'Pools',
  CANDLES: 'Candles',
}

export const Administration = () => {
  const { allPools } = usePools()
  const [selectedTab, setSelectedTab] = useState<string>(ADMIN_TABS.USERS)

  const toggleTabs = (name: string) => {
    setSelectedTab(name)
  }

  return (
    <>
      <ul
        className='w-fit font-semibold border-b border-white-dark dark:border-card whitespace-nowrap overflow-y-auto bottom-0 mx-auto'
        style={{ maxWidth: '90%' }}
      >
        {Object.keys(ADMIN_TABS).map((tabKey) => (
          <li key={tabKey} className='inline-block'>
            <button
              onClick={() => toggleTabs(ADMIN_TABS[tabKey as keyof typeof ADMIN_TABS])}
              className={`flex gap-2 p-4 border-b border-transparent hover:border-white hover:text-white ${
                selectedTab === ADMIN_TABS[tabKey as keyof typeof ADMIN_TABS] ? '!border-white text-white' : ''
              }`}
            >
              {ADMIN_TABS[tabKey as keyof typeof ADMIN_TABS]}
            </button>
          </li>
        ))}
      </ul>

      <div className={'flex flex-col w-full text-white-dark overflow-auto h-screen px-4 pb-14'}>
        <div className='w-full flex flex-col gap-2 overflow-scroll'>
          {selectedTab === ADMIN_TABS.USERS && (
            <>
              <div className='w-full flex flex-col gap-2'>
                <div className='w-full text-xl font-semibold justify-start mt-2 text-primary'>Users</div>
                <Separator className='mb-2' />
                <AddRemoveUserBalance />
                <TableUserRoles />
              </div>
            </>
          )}

          {selectedTab === ADMIN_TABS.BOTS && (
            <div className='w-full flex flex-col gap-2'>
              <div className='w-full text-xl font-semibold justify-start mt-2 text-primary'>Bots</div>
              <Separator className='mb-2' />
              <OrderBots />
              <TraderBots />
            </div>
          )}

          {selectedTab === ADMIN_TABS.ASSET && (
            <div className='w-full flex flex-col gap-2'>
              <div className='w-full text-xl font-semibold justify-start mt-2 text-primary'>Assets</div>
              <Separator className='mb-2' />
              <CreateAssetForm />
              <TableAssets />
              <TableModulePositions />
              <div className='w-full'>
                <div className='w-full text-xl font-semibold justify-start mt-2 text-primary'>Operations</div>
                <Separator className='mb-2' />
                <div className='flex w-full justify-start gap-2'>
                  <SupplyPanel />
                  <CollateralizePanel />
                  <WithdrawPanel />
                  <RepayPanel />
                </div>
              </div>
            </div>
          )}

          {selectedTab === ADMIN_TABS.POOLS && (
            <div className='w-full flex flex-col gap-2'>
              <div className='w-full text-xl font-semibold justify-start mt-2 text-primary'>Pools</div>
              <Separator className='mb-2' />
              <CreateLiquidityPoolForm />
              <TableAdministrationPools columns={tableColumnsFunds} data={allPools} />
            </div>
          )}

          {selectedTab === ADMIN_TABS.CANDLES && (
            <div className='w-full'>
              <CandlesPanel />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
