import { ColumnDef } from '@tanstack/react-table'
import { AggregatedModulePositions } from '@/components/administration/TableModulePositions'
import { createNotification, NotificationType } from '@/utils/notificationUtils'
import { formatNumber } from '@/utils/numberUtils'

export const tableColumnsModulePositions: ColumnDef<AggregatedModulePositions>[] = [
  {
    accessorKey: 'asset',
    header: () => <div className='text-xs text-left text-white'>asset</div>,
    cell: ({ row, table }) => {
      const symbol = table?.options?.meta?.getAsset
        ? (table?.options?.meta?.getAsset(row.original.assetID)?.symbol ?? '')
        : ''
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(symbol)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Asset ${symbol} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {symbol}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'balance',
    header: () => <div className='text-xs text-left text-white'>Balance</div>,
    cell: ({ row, table }) => {
      const asset = table?.options?.meta?.getAsset ? table?.options?.meta?.getAsset(row.original.assetID) : undefined

      const amount = row.original.balance.amount
      const sort = row.original.balance.sortAmount

      const numberAmount = Number.isNaN(amount) ? 0 : Number(amount)
      const numberSort = Number.isNaN(sort) ? 0 : Number(sort)

      return (
        <div key={'module_' + row.original.assetID} className='flex flex-col'>
          <div className='flex flex-col justify-between gap-1 items'>
            <div className='w-2/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Amount</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {formatNumber(numberAmount / Math.pow(10, asset?.decimals ?? 0))}
              </div>
            </div>
            <div className='w-1/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Sort</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {formatNumber(numberSort / Math.pow(10, asset?.decimals ?? 0))}
              </div>
            </div>
          </div>
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'supplied',
    header: () => <div className='text-xs text-left text-white'>Supplied</div>,
    cell: ({ row, table }) => {
      const asset = table?.options?.meta?.getAsset ? table?.options?.meta?.getAsset(row.original.assetID) : undefined

      const amount = row.original.supplied.amount
      const sort = row.original.supplied.sortAmount

      const numberAmount = Number.isNaN(amount) ? 0 : Number(amount)
      const numberSort = Number.isNaN(sort) ? 0 : Number(sort)
      return (
        <div key={'module_' + row.original.assetID} className='flex flex-col'>
          <div className='flex flex-col justify-between gap-1 items'>
            <div className='w-2/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Amount</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {formatNumber(numberAmount / Math.pow(10, asset?.decimals ?? 0))}
              </div>
            </div>
            <div className='w-1/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Sort</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {formatNumber(numberSort / Math.pow(10, asset?.decimals ?? 0))}
              </div>
            </div>
          </div>
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'borrowed',
    header: () => <div className='text-xs text-left text-white'>Borrowed</div>,
    cell: ({ row, table }) => {
      const asset = table?.options?.meta?.getAsset ? table?.options?.meta?.getAsset(row.original.assetID) : undefined

      const amount = row.original.borrowed.amount
      const sort = row.original.borrowed.sortAmount

      const numberAmount = Number.isNaN(amount) ? 0 : Number(amount)
      const numberSort = Number.isNaN(sort) ? 0 : Number(sort)
      return (
        <div key={'module_' + row.original.assetID} className='flex flex-col'>
          <div className='flex flex-col justify-between gap-1 items'>
            <div className='w-2/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Amount</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {formatNumber(numberAmount / Math.pow(10, asset?.decimals ?? 0))}
              </div>
            </div>
            <div className='w-1/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Sort</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {formatNumber(numberSort / Math.pow(10, asset?.decimals ?? 0))}
              </div>
            </div>
          </div>
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'virtual',
    header: () => <div className='text-xs text-left text-white'>Virtual</div>,
    cell: ({ row, table }) => {
      const asset = table?.options?.meta?.getAsset ? table?.options?.meta?.getAsset(row.original.assetID) : undefined

      const amount = row.original.balance.amount
      const sort = row.original.balance.sortAmount

      const numberAmount = Number.isNaN(amount) ? 0 : Number(amount)
      const numberSort = Number.isNaN(sort) ? 0 : Number(sort)
      return (
        <div key={'module_' + row.original.assetID} className='flex flex-col'>
          <div className='flex flex-col justify-between gap-1 items'>
            <div className='w-2/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Amount</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {formatNumber(numberAmount / Math.pow(10, asset?.decimals ?? 0))}
              </div>
            </div>
            <div className='w-1/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Sort</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {formatNumber(numberSort / Math.pow(10, asset?.decimals ?? 0))}
              </div>
            </div>
          </div>
        </div>
      )
    },
    size: 300,
  },
]
