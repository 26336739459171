import { useQuery } from '@tanstack/react-query'
import { useFirebaseAuth } from '@/utils/firebaseAuthProvider'
import { getSdk, User } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { useQueryTime } from '@/hooks/useQueryTime'
import { useNavigate } from 'react-router-dom'
import { queryClient } from '@/services/api'
import { getAuth, signOut } from 'firebase/auth'
import { useEffect } from 'react'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'

export const useUser = () => {
  const user = useFirebaseAuth()
  const navigate = useNavigate()
  const {
    isLoading: loadingUser,
    isFetching,
    data: userData,
    status,
    error,
    isError,
  } = useQuery({
    queryKey: ['USER'],
    queryFn: () => getSdk(graphQLClient).InitUser(),
    enabled: !!user?.uid,
    retry: 0,
    select: (data) => {
      if (!data) {
        signOut(getAuth()).finally(() => {
          queryClient.clear()
        })
      } else {
        return data
      }
    },
  })

  useEffect(() => {
    if (isFetching) {
      const interval = setInterval(() => {
        signOut(getAuth()).finally(() => {
          queryClient.clear()
        })
        createNotification(
          'API is down',
          'Unable to retrieve user information.',
          NotificationType.SIMPLE,
          NotificationStatus.ERROR,
        )
        navigate('/auth/sign-in', { replace: true })
      }, 60000)
      return () => clearInterval(interval)
    }
  }, [isFetching])

  useQueryTime(`['USER', ${user?.uid}]`, status)
  return { loadingUser, user: userData?.initUser as User }
}
