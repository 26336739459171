import InfoCard from '@/components/InfoCard'
import BondHeader from '@/components/BondHeader'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { useAsset } from '@/hooks/useAsset'
import { AssetBond } from '@/graphql/generated/graphql-request'
import dayjs from 'dayjs'
import { ErrorBoundary } from 'react-error-boundary'
import { TvChart } from '@/components/charts/TvChart'

export const Bonds = () => {
  const { orderBookId } = useParams()
  const isin = orderBookId?.split('-')[0]
  const { asset } = useAsset(isin ?? '')
  const bond = asset?.bond as AssetBond | undefined

  const getDateString = (date: string | undefined) => {
    if (!date || !dayjs(date).isValid()) return 'n/a'

    return dayjs(date).format('YYYY-MM-DD')
  }

  const generalInfoItems = useMemo(() => {
    if (!asset) return null
    return {
      Name: bond?.name,
      Issuer: bond?.issuer,
      'Issue Date': bond?.issueDate,
      Maturity: getDateString(bond?.maturityDate),
      Yield: bond?.yield,
    }
  }, [asset])

  const couponInfoItems = useMemo(() => {
    if (!asset) return null
    return {
      'No. of Payments per Year': bond?.numberOfPaymentsPerYear,
      'Coupon Start Date': getDateString(bond?.couponStartDate),
      'Final Coupon Date': getDateString(bond?.finalCouponDate),
    }
  }, [asset])

  if (!asset) return <div> Invalid Bond</div>

  return (
    <div className='p-2 h-full '>
      <div className='mb-2'>{bond && <BondHeader bond={bond} />}</div>

      <div className='py-4 grid grid-cols-1 lg:grid-cols-[500px_auto] w-full gap-2 h-full'>
        <div className='lg:col-start-1 mt-4 lg:mt-0 flex flex-col gap-2'>
          <div>{!!generalInfoItems && <InfoCard title={'General Info'} values={generalInfoItems} />}</div>
          <div>{!!couponInfoItems && <InfoCard title={'Coupon'} values={couponInfoItems} />}</div>
        </div>

        <div className='lg:col-start-2 h-full pb-32'>
          <ErrorBoundary fallback={<p>Something went wrong with Chart</p>}>
            <TvChart />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  )
}
