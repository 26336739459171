import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Candle } from '@/graphql/generated/graphql-request'

interface CandleDataByMarket {
  data: Record<string, Candle[]>
  selectedResolution: string
}
// Step 2: Define the initial state
const initialState = {
  chartLastPrice: {} as Record<string, number>,
  candles: {} as Record<string, CandleDataByMarket>,
}

// Step 3: Create the slice
const chartPriceSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setChartCandles(state, action: PayloadAction<{ candles: Candle[]; marketId: string; resolution: string }>) {
      const { candles, marketId, resolution } = action.payload
      state.candles[marketId] = {
        data: {
          [resolution]: candles,
        },
        selectedResolution: resolution,
      }
    },
    setLastChartPrice(state, action) {
      const updatedValue = { ...state.chartLastPrice }
      updatedValue[action.payload.ticker] = action.payload.price
      state.chartLastPrice = updatedValue
    },
    clearAll(state) {
      state.chartLastPrice = {}
      state.candles = {}
    },
  },
})

// Step 4: Export the actions and reducer
export const { setChartCandles, setLastChartPrice, clearAll } = chartPriceSlice.actions
export default chartPriceSlice.reducer
