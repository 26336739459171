import { GraphQLClient, RequestMiddleware } from 'graphql-request'
import { getAuth } from 'firebase/auth'
import { createClient } from 'graphql-ws'
import * as Sentry from '@sentry/react'

const requestMiddleware: RequestMiddleware = async (request) => {
  const token = (await getAuth().currentUser?.getIdToken()) ?? localStorage.getItem('firebaseToken') ?? ''
  return {
    ...request,
    headers: { ...request.headers, authorization: token },
  }
}
export const graphQLClient = new GraphQLClient(import.meta.env.VITE_APP_GRAPHQL_URL as string, {
  requestMiddleware,
  keepalive: true,
})

let retryCount = 0

function exponentialBackoff() {
  //up to a max of 10 seconds
  const delay = Math.max(Math.pow(2, retryCount) * 1000, 10000) // Exponential backoff formula (2^retryCount * 1000ms)

  setTimeout(() => {
    window.location.reload()
  }, delay)

  retryCount++
}

export const wsClient = createClient({
  url: import.meta.env.VITE_APP_GRAPHQL_SOCKET_URL ?? 'wss://bond-api.network.dora.tech/graphql',
  keepAlive: 30000,
  retryAttempts: 30,
  shouldRetry: (error) => {
    Sentry.captureMessage(`Socket Client shouldRetry:`, {
      extra: {
        error: JSON.stringify(error),
      },
    })
    return true
  },
  connectionParams: async () => {
    const token = (await getAuth().currentUser?.getIdToken()) ?? localStorage.getItem('firebaseToken') ?? ''
    return { access_token: token }
  },
  on: {
    connected: () => {
      console.log('Socket Connected')
    },
    error: (error) => {
      console.log('Socket Error', error)
      Sentry.captureMessage(`Socket Client on error:`, {
        extra: {
          error: JSON.stringify(error),
        },
      })
    },
    closed: (context) => {
      console.log('Socket Closed')
      Sentry.captureMessage(`Socket Client on closed:`, {
        extra: {
          context: JSON.stringify(context),
        },
      })
      //reload on error
      exponentialBackoff()
    },
    ping: () => {
      console.log('Socket Ping')
    },
    pong: () => {
      console.log('Socket Pong')
    },
    connecting: () => {
      console.log('Socket Connecting...')
    },
    opened: () => {
      console.log('Socket Opened')
    },
    message: (message) => {
      //console.log('Socket Message', message)
    },
  },
})
