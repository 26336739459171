export const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID,
}

export const Environment = {
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
  firestoreEmulatorHost: import.meta.env.VITE_APP_FIRESTORE_EMULATOR_HOST,
  firebaseAuthEmulatorHost: import.meta.env.VITE_APP_FIREBASE_AUTH_EMULATOR_HOST,
}
