import { ColumnDef } from '@tanstack/react-table'
import { Asset } from '@/graphql/generated/graphql-request'

export const tableColumnsMyAssetsMobile: ColumnDef<Asset>[] = [
  {
    accessorKey: 'Symbol',
    header: () => <div className='text-xs text-left text-white'>Name</div>,
    cell: ({ row }) => {
      const symbol = row.original.symbol
      return <div className='border-0 text-left text-xs text-white-dark font-semibold'>{symbol}</div>
    },
  },
  {
    accessorKey: 'Available',
    header: () => <div className='text-xs text-left text-white'>Available Balance</div>,
    cell: ({ row, table }) => {
      const balance = table?.options?.meta?.getUserAvailableBalanceForAsset
        ? table?.options?.meta?.getUserAvailableBalanceForAsset(row.original, true)
        : '0'
      return <div className='border-0 text-left text-xs text-white-dark font-semibold'>{balance}</div>
    },
  },
]
