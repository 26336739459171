import { useCallback, useEffect, useMemo, useState } from 'react'
import { getSdk, TraderStrategyName, TraderStrategyOption, UpsertTraderBot } from '@/graphql/generated/graphql-request'
import { useTraderBots } from '@/hooks/useTraderBots'
import { useMutation } from '@tanstack/react-query'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { queryClient } from '@/services/api'
import { useOrderBooks } from '@/hooks/useOrderBooks'

interface CreateTraderBotModalProps {
  traderBotID?: string | null
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const CreateTraderBotModal = ({ traderBotID, isOpen, setIsOpen }: CreateTraderBotModalProps) => {
  const { traderBots } = useTraderBots()
  const { orderBooks } = useOrderBooks()
  const [name, setName] = useState('')
  const [orderBookID, setOrderBookID] = useState(orderBooks[0]?.orderBookID || '')
  const [botID, setBotID] = useState(traderBotID || '')
  const [amountList, setAmountList] = useState<string>('')
  const [amountMult, setAmountMult] = useState<number | undefined>(undefined)
  const [bidExePercentage, setBidExePercentage] = useState<number | undefined>(undefined)
  const [dps, setDps] = useState<number | undefined>(undefined)
  const [levels, setLevels] = useState<number | undefined>(undefined)
  const [marketOrderFilePath, setMarketOrderFilePath] = useState<string>('')
  const [maxAskPrice, setMaxAskPrice] = useState<number | undefined>(undefined)
  const [maxBidPercentage, setMaxBidPercentage] = useState<number | undefined>(undefined)
  const [maxSkew, setMaxSkew] = useState<number | undefined>(undefined)
  const [maxSwapAmount, setMaxSwapAmount] = useState<number | undefined>(undefined)
  const [maxTakePercentage, setMaxTakePercentage] = useState<number | undefined>(undefined)
  const [minBidPrice, setMinBidPrice] = useState<number | undefined>(undefined)
  const [minDelayBetweenOrders, setMinDelayBetweenOrders] = useState<number | undefined>(undefined)
  const [minSkew, setMinSkew] = useState<number | undefined>(undefined)
  const [minSwapAmount, setMinSwapAmount] = useState<number | undefined>(undefined)
  const [minTakePercentage, setMinTakePercentage] = useState<number | undefined>(undefined)
  const [orderInterval, setOrderInterval] = useState<number | undefined>(undefined)
  const [orderQuantity, setOrderQuantity] = useState<number | undefined>(undefined)
  const [spread, setSpread] = useState<number | undefined>(undefined)
  const [strategyName, setStrategyName] = useState<TraderStrategyName>(TraderStrategyName.Market)
  const [swapQuantity, setSwapQuantity] = useState<number | undefined>(undefined)
  const [targetPrice, setTargetPrice] = useState<number | undefined>(undefined)
  const [tickSize, setTickSize] = useState<number | undefined>(undefined)
  const [deltaLevels, setDeltaLevels] = useState<{ delta: number; level: number }[]>([])
  const [deltaMults, setDeltaMults] = useState<{ delta: number; mult: number }[]>([])
  const [deltaLevelsInput, setDeltaLevelsInput] = useState<string>('')
  const [deltaMultsInput, setDeltaMultsInput] = useState<string>('')
  const [option, setOption] = useState<TraderStrategyOption>(TraderStrategyOption.Levels)
  const traderBot = useMemo(() => traderBots.find((bot) => bot.botID === traderBotID), [traderBotID, traderBots])
  const title = useMemo(() => (traderBot ? 'Edit Trader Bot' : 'Create Trader Bot'), [traderBot])
  const buttonLabel = useMemo(() => (traderBot ? 'Update' : 'Create'), [traderBot])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleDeltaLevelsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeltaLevelsInput(e.target.value)
  }

  const handleDeltaLevelsBlur = () => {
    const input = deltaLevelsInput.trim()
    if (input) {
      const parsedLevels = input.split(',').map((pair) => {
        const [delta, level] = pair.split(':').map(Number)
        return { delta: isNaN(delta) ? 0 : delta, level: isNaN(level) ? 0 : level }
      })
      setDeltaLevels(parsedLevels)
    } else {
      setDeltaLevels([])
    }
  }

  const handleDeltaMultsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeltaMultsInput(e.target.value)
  }

  const handleDeltaMultsBlur = () => {
    const input = deltaMultsInput.trim()
    if (input) {
      const parsedMults = input.split(',').map((pair) => {
        const [delta, mult] = pair.split(':').map(Number)
        return { delta: isNaN(delta) ? 0 : delta, mult: isNaN(mult) ? 0 : mult }
      })
      setDeltaMults(parsedMults)
    } else {
      setDeltaMults([])
    }
  }

  const reset = () => {
    setAmountList('')
    setAmountMult(undefined)
    setBidExePercentage(undefined)
    setBotID('')
    setDeltaLevels([])
    setDeltaLevelsInput('')
    setDeltaMults([])
    setDeltaMultsInput('')
    setDps(undefined)
    setLevels(undefined)
    setMarketOrderFilePath('')
    setMaxAskPrice(undefined)
    setMaxBidPercentage(undefined)
    setMaxSkew(undefined)
    setMaxSwapAmount(undefined)
    setMaxTakePercentage(undefined)
    setMinBidPrice(undefined)
    setMinDelayBetweenOrders(undefined)
    setMinSkew(undefined)
    setMinSwapAmount(undefined)
    setMinTakePercentage(undefined)
    setName('')
    setOption(TraderStrategyOption.Levels)
    setOrderBookID('')
    setOrderInterval(undefined)
    setOrderQuantity(undefined)
    setSpread(undefined)
    setStrategyName(TraderStrategyName.Market)
    setSwapQuantity(undefined)
    setTargetPrice(undefined)
    setTickSize(undefined)
  }

  useEffect(() => {
    if (isOpen && traderBot) {
      setAmountList(traderBot.amountList.join(','))
      setAmountMult(traderBot.amountMult)
      setBidExePercentage(traderBot.bidExePercentage)
      setBotID(traderBot.botID)
      setDeltaLevels(traderBot.deltaLevels)
      setDeltaLevelsInput(traderBot.deltaLevels.map(({ delta, level }) => `${delta}:${level}`).join(', '))
      setDeltaMults(traderBot.deltaMults)
      setDeltaMultsInput(traderBot.deltaMults.map(({ delta, mult }) => `${delta}:${mult}`).join(', '))
      setDps(traderBot.dps)
      setLevels(traderBot.levels)
      setMarketOrderFilePath(traderBot.marketOrderFilePath)
      setMaxAskPrice(traderBot.maxAskPrice)
      setMaxBidPercentage(traderBot.maxBidPercentage)
      setMaxSkew(traderBot.maxSkew)
      setMaxSwapAmount(traderBot.maxSwapAmount)
      setMaxTakePercentage(traderBot.maxTakePercentage)
      setMinBidPrice(traderBot.minBidPrice)
      setMinDelayBetweenOrders(traderBot.minDelayBetweenOrders)
      setMinSkew(traderBot.minSkew)
      setMinSwapAmount(traderBot.minSwapAmount)
      setMinTakePercentage(traderBot.minTakePercentage)
      setName(traderBot.name)
      setOption(traderBot.option)
      setOrderBookID(traderBot.orderBookID)
      setOrderInterval(traderBot.orderInterval)
      setOrderQuantity(traderBot.orderQuantity)
      setSpread(traderBot.spread)
      setStrategyName(traderBot.strategyName)
      setSwapQuantity(traderBot.swapQuantity)
      setTargetPrice(traderBot.targetPrice)
      setTickSize(traderBot.tickSize)
    } else if (!isOpen) {
      reset()
    }
  }, [isOpen, traderBot])

  const upsertTraderBotMutation = useMutation({
    mutationFn: (newTraderBot: UpsertTraderBot) =>
      getSdk(graphQLClient)
        .UpsertTraderBot({ bot: newTraderBot })
        .then(() => {
          const message = traderBot ? 'Trader Bot was updated successfully' : 'Trader Bot was created successfully'
          createNotification('Trader Bot', message, NotificationType.SIMPLE)
        })
        .catch((err) => {
          const message = err?.message ?? (traderBot ? 'Trader Bot was not updated' : 'Trader Bot was not created')
          createNotification('Trader Bot', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['TRADER_BOTS'] }).finally()
      handleClose()
    },
  })

  const handleSubmit = useCallback(() => {
    const newTraderBot: UpsertTraderBot = {
      botID: botID || '',
      name: name || '',
      orderBookID: orderBookID || '',
      minSwapAmount: minSwapAmount ?? 0,
      maxSwapAmount: maxSwapAmount ?? 0,
      orderQuantity: orderQuantity ?? 0,
      swapQuantity: swapQuantity ?? 0,
      bidExePercentage: bidExePercentage ?? 0,
      orderInterval: orderInterval ?? 0,
      minDelayBetweenOrders: minDelayBetweenOrders ?? 0,
      strategyName: strategyName,
      spread: spread ?? 0,
      levels: levels ?? 0,
      minTakePercentage: minTakePercentage ?? 0,
      maxTakePercentage: maxTakePercentage ?? 0,
      maxBidPercentage: maxBidPercentage ?? 0,
      dps: dps ?? 0,
      minSkew: minSkew ?? 0,
      maxSkew: maxSkew ?? 0,
      minBidPrice: minBidPrice ?? 0,
      maxAskPrice: maxAskPrice ?? 0,
      tickSize: tickSize ?? 0,
      amountMult: amountMult ?? 0,
      targetPrice: targetPrice ?? 0,
      amountList: amountList.split(',').map(Number),
      deltaLevels: deltaLevels.map(({ delta, level }) => ({ delta: delta ?? 0, level: level ?? 0 })),
      deltaMults: deltaMults.map(({ delta, mult }) => ({ delta: delta ?? 0, mult: mult ?? 0 })),
      option: option,
      marketOrderFilePath: marketOrderFilePath || '',
    }
    console.log('Trader Bot mutation:', newTraderBot)
    upsertTraderBotMutation.mutate(newTraderBot)
  }, [
    botID,
    name,
    orderBookID,
    minSwapAmount,
    maxSwapAmount,
    orderQuantity,
    swapQuantity,
    bidExePercentage,
    orderInterval,
    minDelayBetweenOrders,
    strategyName,
    spread,
    levels,
    minTakePercentage,
    maxTakePercentage,
    maxBidPercentage,
    dps,
    minSkew,
    maxSkew,
    minBidPrice,
    maxAskPrice,
    tickSize,
    amountMult,
    targetPrice,
    amountList,
    deltaLevels,
    deltaMults,
    option,
    marketOrderFilePath,
    handleClose,
  ])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen])

  return (
    <div>
      {isOpen && (
        <div className={'relative z-40'} onClick={handleClose}>
          <div className={'fixed bg-black/80 w-full h-full top-0 left-0 right-0 bottom-0'}>
            <div
              id={'create-traderbot-modal'}
              className={
                'w-full p-4 bg-panel flex flex-col justify-center items-center border border-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg'
              }
              onClick={(e) => e.stopPropagation()}
            >
              <button onClick={handleClose} className={'absolute top-2 right-2 text-white text-xl'}>
                &times;
              </button>
              <div className={'text-primary text-xl font-semibold mb-6 '}>{title}</div>
              <div className={'grid grid-cols-6 gap-6 text-center items-center'}>
                <label className='text-white'>Name</label>
                <input
                  type='text'
                  placeholder='Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Bot ID</label>
                <input
                  type='text'
                  placeholder='Bot ID'
                  value={botID}
                  disabled={!!traderBotID}
                  onChange={(e) => setBotID(e.target.value)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>OrderBook ID</label>
                <select
                  value={orderBookID}
                  onChange={(e) => setOrderBookID(e.target.value)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                >
                  {orderBooks.map((orderBook) => (
                    <option key={orderBook.orderBookID} value={orderBook.orderBookID}>
                      {orderBook.orderBookID}
                    </option>
                  ))}
                </select>
                <label className='text-white'>Min Swap Amount</label>
                <input
                  type='number'
                  placeholder='Min Swap Amount'
                  value={minSwapAmount ?? ''}
                  onChange={(e) => setMinSwapAmount(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Max Swap Amount</label>
                <input
                  type='number'
                  placeholder='Max Swap Amount'
                  value={maxSwapAmount ?? ''}
                  onChange={(e) => setMaxSwapAmount(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Order Quantity</label>
                <input
                  type='number'
                  placeholder='Order Quantity'
                  value={orderQuantity ?? ''}
                  onChange={(e) => setOrderQuantity(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Swap Quantity</label>
                <input
                  type='number'
                  placeholder='Swap Quantity'
                  value={swapQuantity ?? ''}
                  onChange={(e) => setSwapQuantity(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Bid Exe Percentage</label>
                <input
                  type='number'
                  placeholder='Bid Exe Percentage'
                  value={bidExePercentage ?? ''}
                  onChange={(e) => setBidExePercentage(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Order Interval</label>
                <input
                  type='number'
                  placeholder='Order Interval'
                  value={orderInterval ?? ''}
                  onChange={(e) => setOrderInterval(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Min Delay Between Orders</label>
                <input
                  type='number'
                  placeholder='Min Delay Between Orders'
                  value={minDelayBetweenOrders ?? ''}
                  onChange={(e) => setMinDelayBetweenOrders(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Strategy</label>
                <select
                  value={strategyName}
                  onChange={(e) => setStrategyName(e.target.value as TraderStrategyName)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                >
                  <option value={TraderStrategyName.Market}>Market</option>
                  <option value={TraderStrategyName.Taker}>Taker</option>
                  <option value={TraderStrategyName.TargetPrice}>Target Price</option>
                </select>
                <label className='text-white'>Spread</label>
                <input
                  type='number'
                  placeholder='Spread'
                  value={spread ?? ''}
                  onChange={(e) => setSpread(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Levels</label>
                <input
                  type='number'
                  placeholder='Levels'
                  value={levels ?? ''}
                  onChange={(e) => setLevels(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Min Take Percentage</label>
                <input
                  type='number'
                  placeholder='Min Take Percentage'
                  value={minTakePercentage ?? ''}
                  onChange={(e) => setMinTakePercentage(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Max Take Percentage</label>
                <input
                  type='number'
                  placeholder='Max Take Percentage'
                  value={maxTakePercentage ?? ''}
                  onChange={(e) => setMaxTakePercentage(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Max Bid Percentage</label>
                <input
                  type='number'
                  placeholder='Max Bid Percentage'
                  value={maxBidPercentage ?? ''}
                  onChange={(e) => setMaxBidPercentage(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>DPS</label>
                <input
                  type='number'
                  placeholder='DPS'
                  value={dps ?? ''}
                  onChange={(e) => setDps(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Min Skew</label>
                <input
                  type='number'
                  placeholder='Min Skew'
                  value={minSkew ?? ''}
                  onChange={(e) => setMinSkew(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Max Skew</label>
                <input
                  type='number'
                  placeholder='Max Skew'
                  value={maxSkew ?? ''}
                  onChange={(e) => setMaxSkew(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Min Bid Price</label>
                <input
                  type='number'
                  placeholder='Min Bid Price'
                  value={minBidPrice ?? ''}
                  onChange={(e) => setMinBidPrice(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Max Ask Price</label>
                <input
                  type='number'
                  placeholder='Max Ask Price'
                  value={maxAskPrice ?? ''}
                  onChange={(e) => setMaxAskPrice(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Tick Size</label>
                <input
                  type='number'
                  placeholder='Tick Size'
                  value={tickSize ?? ''}
                  onChange={(e) => setTickSize(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Amount Mult</label>
                <input
                  type='number'
                  placeholder='Amount Mult'
                  value={amountMult ?? ''}
                  onChange={(e) => setAmountMult(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Target Price</label>
                <input
                  type='number'
                  placeholder='Target Price'
                  value={targetPrice ?? ''}
                  onChange={(e) => setTargetPrice(Number(e.target.value))}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Amount List (comma separated)</label>
                <input
                  type='text'
                  placeholder='10, 50, 600....'
                  value={amountList}
                  onChange={(e) => setAmountList(e.target.value)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Market Order File Path</label>
                <input
                  type='text'
                  placeholder='Market Order File Path'
                  value={marketOrderFilePath}
                  onChange={(e) => setMarketOrderFilePath(e.target.value)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Delta Levels (comma separated pairs of delta:level)</label>
                <input
                  type='text'
                  placeholder='0.1:1, 0.2:2, ...'
                  value={deltaLevelsInput}
                  onChange={handleDeltaLevelsChange}
                  onBlur={handleDeltaLevelsBlur}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Delta Mults (comma separated pairs of delta:mult)</label>
                <input
                  type='text'
                  placeholder='0.1:1, 0.2:2, ...'
                  value={deltaMultsInput}
                  onChange={handleDeltaMultsChange}
                  onBlur={handleDeltaMultsBlur}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <label className='text-white'>Option</label>
                <select
                  value={option}
                  onChange={(e) => setOption(e.target.value as TraderStrategyOption)}
                  className='mx-1 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                >
                  <option value={TraderStrategyOption.Levels}>Option 1</option>
                  <option value={TraderStrategyOption.Mults}>Option 2</option>
                  <option value={TraderStrategyOption.Random}>Option 2</option>
                  {/* Add other options as needed */}
                </select>
              </div>
              <button
                onClick={handleSubmit}
                className='mt-8 mb-4 btn bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
              >
                {buttonLabel}
              </button>
            </div>
          </div>
        </div>
      )}
      <button
        type='button'
        onClick={() => setIsOpen(true)}
        className='btn bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
      >
        Create Trader Bot
      </button>
    </div>
  )
}
