import { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import App from '../../App'
import { IRootState } from '@/store'
import { toggleLayout } from '@/store/themeConfigSlice'
import Footer from './Footer'
import Header from './Header'
import Portals from '../../components/Portals'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import { MobileMenu } from '@/components/Layouts/MobileMenu'
import { useQuery } from '@tanstack/react-query'
import { TIME_DURATION } from '@/utils/constants'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/react'
import { getAuth, signOut } from 'firebase/auth'
import { useIdle } from '@uidotdev/usehooks'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import useSignOutAndClearStore from '@/hooks/useSignOutAndClearStore'
import { initializeCustomScrollbar } from '@/utils/UIutils'

const DefaultLayout = () => {
  const isIdle = useIdle(import.meta.env.VITE_APP_USER_INACTIVITY_TIMEOUT ?? 1000 * 60 * 60 * 48) // default 48 hours
  const themeConfig = useSelector((state: IRootState) => state.themeConfig)
  const dispatch = useDispatch()
  const { isTablet } = useBreakpoints()
  const [showLoader, setShowLoader] = useState(true)
  const [showTopButton, setShowTopButton] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const location = useLocation()
  const signOutAndClearStore = useSignOutAndClearStore()
  const navigate = useNavigate()

  const { isError } = useQuery({
    queryKey: ['apiStatus'],
    queryFn: async () => {
      const response = await fetch(import.meta.env.VITE_APP_API_URL)
      console.log('response', response)
      if (!(response.status === 200)) {
        throw new Error('Network response was not ok')
      }
      return true
    },
    refetchInterval: TIME_DURATION.DAY,
    retry: false,
  })

  const goToTop = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  const onScrollHandler = () => {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      setShowTopButton(true)
    } else {
      setShowTopButton(false)
    }
  }

  useEffect(() => {
    if (isIdle) {
      navigate('/auth/sign-in')
      signOutAndClearStore()
        .catch((error) => {
          console.error('Error signing out', error)
        })
        .then(() => {
          createNotification(
            'User Inactivity',
            'User logged out due to inactivity',
            NotificationType.SIMPLE,
            NotificationStatus.INFO,
          )
        })
    }
  }, [isIdle])

  useEffect(() => {
    window.addEventListener('scroll', onScrollHandler, { passive: true })

    dispatch(toggleLayout('full'))
    //dispatch(toggleLayout('boxed-layout'))

    const screenLoader = document.getElementsByClassName('screen_loader')
    if (screenLoader?.length) {
      screenLoader[0].classList.add('animate__fadeOut')
      setTimeout(() => {
        setShowLoader(false)
      }, 200)
    }

    return () => {
      window.removeEventListener('onscroll', onScrollHandler)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('load', initializeCustomScrollbar)

    return () => {
      window.removeEventListener('load', initializeCustomScrollbar)
    }
  }, [])

  if (isError) {
    return (
      <div className={'w-full h-full relative flex-row'}>
        <div className=' h-fit flex items-center justify-center'>
          <div className='relative text-center font-semibold '>
            <img src='/assets/images/404.svg' alt='404' className='mx-auto w-full max-w-[630px] object-cover' />
            <p className='mt-5 text-base dark:text-white'>API is down - try again later!</p>
            <a href={import.meta.env.VITE_APP_API_URL} className='text-primary visited:text-secondary ...'>
              {import.meta.env.VITE_APP_API_URL}
            </a>

            <Link to='/' className='font-bold btn btn-gradient mx-auto !mt-7 w-max border-0 uppercase shadow-none'>
              Home
            </Link>
          </div>
        </div>
        <div className={'w-full z-50 absolute bottom-0'}>
          <Footer isApiAvailable={false} />
        </div>
      </div>
    )
  }

  return (
    <App>
      {/*<Setting />*/}
      {showLoader && (
        <div className='screen_loader fixed inset-0 bg-[#fafafa] dark:bg-[#060818] z-[60] grid place-content-center animate__animated'>
          <svg width='64' height='64' viewBox='0 0 135 135' xmlns='http://www.w3.org/2000/svg' fill='#4361ee'>
            <path d='M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z'>
              <animateTransform
                attributeName='transform'
                type='rotate'
                from='0 67 67'
                to='-360 67 67'
                dur='2.5s'
                repeatCount='indefinite'
              />
            </path>
            <path d='M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z'>
              <animateTransform
                attributeName='transform'
                type='rotate'
                from='0 67 67'
                to='360 67 67'
                dur='8s'
                repeatCount='indefinite'
              />
            </path>
          </svg>
        </div>
      )}
      <Header setIsMobileMenuOpen={setIsMobileMenuOpen} isMobileMenuOpen={isMobileMenuOpen} />
      <div
        id='main'
        className='grid grid-cols-1 flex-grow text-black dark:text-white-dark dark:bg-black-trueBlack overflow-x-scroll mb-[24px]'
        style={{ scrollbarWidth: 'none', transformStyle: 'inherit' }}
      >
        {isTablet && isMobileMenuOpen && <MobileMenu setIsMobileMenuOpen={setIsMobileMenuOpen} />}
        {!isMobileMenuOpen && (
          <Suspense>
            <div className={`${themeConfig.animation} animate__animated`}>
              <ErrorBoundary
                FallbackComponent={({ error, resetErrorBoundary }) => {
                  Sentry.captureMessage(`ErrorBoundary - Crash Default`, {
                    extra: {
                      defaultLayout: true,
                      location: location.pathname,
                      error: error.message,
                    },
                  })
                  return (
                    <div role='alert'>
                      <p className='text-xl text-white'>Something went wrong, contact Frontend Team</p>
                      <p className='text-xl text-red-500'>Let them know about the following error:</p>
                      <pre className='my-8'>{error.message}</pre>
                      <button
                        onClick={resetErrorBoundary}
                        className={`m-8 flex gap-2 px-2 py-1 mx-0.5 bg-red-400 text-black border-b border-transparent hover:bg-dark hover:text-white hover:rounded-t`}
                      >
                        Click here to try again and reload website
                      </button>
                    </div>
                  )
                }}
                onReset={() => signOut(getAuth())}
              >
                <Outlet />
              </ErrorBoundary>
            </div>
          </Suspense>
        )}
      </div>
      <Footer isApiAvailable={true} />
      <Portals />
    </App>
  )
}

export default DefaultLayout
