import { AssetBond } from '@/graphql/generated/graphql-request'

interface BondHeaderProps {
  bond: AssetBond
}

const BondHeader = ({ bond }: BondHeaderProps) => {
  return (
    <div className='mb-2'>
      <div className='flex justify-between items-center mb-5'>
        <h1 className='text-dark dark:text-white text-2xl'>Bond: {bond.name}</h1>
      </div>
      <div className='w-full h-[1px] bg-gradient-to-r from-secondary to-primary' />
    </div>
  )
}

export default BondHeader
