import { Asset, AssetType } from '@/graphql/generated/graphql-request'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import { tableColumnsMyAssetsMobile } from '@/components/myAssets/TableColumnsMyAssetsMobile'
import { useAllAssets } from '@/hooks/useAsset'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination'
import { useUserPosition } from '@/hooks/useUserPosition'
import { tableColumnsUserBalances } from '@/components/administration/TableColumnsUserBalances'
import { TailSpin } from 'react-loader-spinner'

const pageSize = 10

interface TableUserBalancesProps {
  userUID: string
}

export function TableUserBalances({ userUID }: TableUserBalancesProps) {
  const [currentPage, setCurrentPage] = useState(0)
  const navigate = useNavigate()
  const { allAssets, loadingAssets } = useAllAssets()
  const { userPosition, loadingUserPosition } = useUserPosition({ userUid: userUID })
  const { isTablet } = useBreakpoints()

  const isLoading = loadingAssets || loadingUserPosition

  const assetUIDs = new Set([
    ...allAssets
      .filter(
        (asset) =>
          userPosition?.available?.some((b) => b.assetID === asset.uid) ||
          userPosition?.locked?.some((b) => b.assetID === asset.uid) ||
          userPosition?.borrowed.some((b) => b.assetID === asset.uid) ||
          userPosition?.supplied.some((b) => b.assetID === asset.uid),
      )
      .map((asset) => asset.uid),
  ])

  const myAssets = useMemo(() => allAssets.filter((asset) => assetUIDs.has(asset.uid)), [allAssets, assetUIDs])

  const assets = useMemo(
    () =>
      Array.from({ length: Math.ceil(myAssets.length / pageSize) }, (_, i) =>
        myAssets.slice(i * pageSize, i * pageSize + pageSize),
      ),
    [myAssets],
  )

  const columns = useMemo(() => {
    return isTablet ? tableColumnsMyAssetsMobile : tableColumnsUserBalances
  }, [isTablet, userPosition])

  const getUserAvailableBalanceForAsset = useCallback(
    (asset: Asset, available: boolean) => {
      const balance = available
        ? userPosition?.available?.find((b) => b.assetID === asset.uid)
        : userPosition?.locked?.find((b) => b.assetID === asset.uid)
      return (Number(balance?.amount ?? 0) / Math.pow(10, asset.decimals)).toString()
    },
    [allAssets, userPosition],
  )

  const getUserPositionForAsset = useCallback(
    (asset: Asset, borrow: boolean) => {
      const position = borrow
        ? userPosition?.borrowed?.find((b) => b.assetID === asset.uid)
        : userPosition?.supplied?.find((b) => b.assetID === asset.uid)
      return (Number(position?.amount ?? 0) / Math.pow(10, asset.decimals)).toString()
    },
    [allAssets, userPosition],
  )

  const table = useReactTable({
    data: assets[currentPage] ?? [],
    columns,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      getUserAvailableBalanceForAsset,
      getUserPositionForAsset,
    },
  })

  const headers = useMemo(() => {
    return table.getHeaderGroups()
  }, [isTablet, currentPage, userPosition])

  const rows = useMemo(() => {
    return table.getRowModel().rows
  }, [isTablet, currentPage, userPosition])

  const handleRowClick = (asset: Asset) => {
    if (!asset || asset.type === AssetType.Currency) return

    if (asset.type === AssetType.Bond) {
      //send prop addLiquiditySelected as false when navigate to the bond page
      navigate(`/bonds/${asset.uid}-USDY`)
      return
    }

    if (asset.type === AssetType.PoolShare) {
      navigate(`/pool/${asset?.uid}`, { state: { removeLiquiditySelected: true } })
    }
  }

  return !isLoading ? (
    myAssets.length > 0 ? (
      <div className='w-full flex flex-col h-full panel'>
        <Table>
          <TableHeader className='text-sm'>
            {headers.map((headerGroup) => (
              <TableRow className='p-1 ' key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className='dark:bg-dark-darker border-b border-b-dark'
                      key={header.id}
                      style={{
                        width: header.getSize(),
                      }}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {rows?.length ? (
              rows.map((row) => (
                <TableRow
                  //use cursor-pointer if asset is not a currency
                  className={`p-1 ${row.getValue('type') !== AssetType.Currency && 'cursor-pointer'}`}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={() => {
                    handleRowClick(row.original)
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell className={`border-b border-dark`} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow className='border p-1'>
                <TableCell colSpan={columns.length} className='text-center'>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {assets.length > 1 && (
          <div className='flex items-center justify-end space-x-2 py-4'>
            <Pagination className='pt-2'>
              <PaginationContent>
                <PaginationItem key={'previous'}>
                  <PaginationPrevious href='#' onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)} />
                </PaginationItem>
                {assets.map((_, index) => (
                  <PaginationItem key={index}>
                    <PaginationLink href='#' isActive={currentPage === index} onClick={() => setCurrentPage(index)}>
                      {index + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem key={'ellipsis'}>
                  <PaginationEllipsis />
                </PaginationItem>
                <PaginationItem key={'next'}>
                  <PaginationNext
                    href='#'
                    onClick={() => {
                      setCurrentPage(currentPage < assets.length - 1 ? currentPage + 1 : assets.length - 1)
                    }}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        )}
      </div>
    ) : (
      <div className='w-full panel dark:bg-card flex flex-col h-full'>
        <div>User has no assets</div>
      </div>
    )
  ) : (
    <div className='w-full panel dark:bg-card flex flex-col h-full justify-center items-center'>
      <TailSpin
        visible={true}
        height='32'
        width='32'
        color={'rgba(33,150,243, 1)'}
        ariaLabel='tail-spin-loading'
        radius='1'
        wrapperStyle={{}}
        wrapperClass=''
      />
    </div>
  )
}
