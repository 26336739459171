import { useEffect, useRef, useState } from 'react'
import type { ResolutionString } from 'public/tradingview/charting_library'
import styled, { css, IStyledComponent } from 'styled-components'
import type { TvWidget } from '@/constants/tvchart'
import { useChartLines, useChartMarketAndResolution, useTradingView, useTradingViewTheme } from '@/hooks/tradingView'
import { layoutMixins } from '@/styles/layoutMixins'
import { LoadingSpace } from '@/components/Loading/LoadingSpinner'

interface StoredObject {
  paneProperties: {
    background: string
    backgroundType: string
  }
}

export const TvChart = () => {
  const [isChartReady, setIsChartReady] = useState(false)
  const tvWidgetRef = useRef<TvWidget | null>(null)
  const tvWidget = tvWidgetRef.current
  const isWidgetReady = tvWidget?._ready

  const displayButtonRef = useRef<HTMLElement | null>(null)
  const displayButton = displayButtonRef.current

  useTradingView({ tvWidgetRef, displayButtonRef, setIsChartReady })
  // useChartMarketAndResolution({
  //   tvWidget,
  //   isWidgetReady,
  //   savedResolution: savedResolution as ResolutionString | undefined,
  // })
  // const { chartLines } = useChartLines({ tvWidget, displayButton, isChartReady })
  // useTradingViewTheme({ tvWidget, isWidgetReady, chartLines })

  // TODO - Check better way to do this change of background on TradingView chart
  useEffect(() => {
    // Step 1: Retrieve the stored object
    const storedString = localStorage.getItem('tradingview.chartproperties')
    if (!storedString) return

    // Step 2: Parse the string into an object
    const storedObject: StoredObject = JSON.parse(storedString)

    // Step 3: Change the paneProperties.background property to white
    storedObject.paneProperties.background = 'rgba(11, 11, 14, 1)'
    storedObject.paneProperties.backgroundType = 'solid'

    // Step 4: Convert the object back into a string
    const updatedObject = JSON.stringify(storedObject)

    // Step 5: Store the updated string back to localStorage
    localStorage.setItem('tradingview.chartproperties', updatedObject)
  }, [isChartReady])

  return (
    <Styled.PriceChart $isChartReady={isChartReady}>
      {!isChartReady && <LoadingSpace id='tv-chart-loading' />}
      <div id='tv-price-chart' className={'panel'} />
    </Styled.PriceChart>
  )
}

const Styled: Record<string, IStyledComponent<'web', FixMe>> = {}

Styled.PriceChart = styled.div<{ $isChartReady?: boolean }>`
  ${layoutMixins.stack}

  height: 100%;

  #tv-price-chart {
    ${({ $isChartReady }) =>
      !$isChartReady &&
      css`
        filter: blur(3px);
        translate: 0 0 1rem;
        opacity: 0;
      `};

    @media (prefers-reduced-motion: no-preference) {
      transition: 0.2s var(--ease-out-expo);
    }
  }
`
