/**
 * Removes non-numeric characters from an input value and formats it as a string with a specified number of decimal places.
 * The function also formats the number with commas as thousand separators.
 *
 * @param {string | number} inputValue - The input value to be sanitized. This can be either a string or a number.
 * @param {number} decimals - The number of decimal places to be included in the output string. Defaults to 2.
 *
 * @returns {string} The sanitized input value as a string, with non-numeric characters removed, a single decimal point,
 * and commas as thousand separators.
 *
 * @example
 * // returns "1,234.56"
 * removeLettersForInput("1,234.5678abc")
 *
 * @example
 * // returns "1,234.56"
 * removeLettersForInput(1234.5678)
 */
export function removeLettersForInput(inputValue: string | number | undefined, decimals: number = 2): string {
  if (!inputValue || inputValue === '') return ''

  const pattern = decimals === 0 ? /[^0-9]/g : /[^0-9.]/g

  // Remove non-numeric characters except the decimal point
  const inputString = inputValue.toString()
  let sanitizedInput = inputString.replace(pattern, '')

  // Ensure there's only one decimal point
  const firstDecimalIndex = sanitizedInput.indexOf('.')
  if (firstDecimalIndex !== -1) {
    const beforeDecimal = sanitizedInput.substring(0, firstDecimalIndex + 1)
    let afterDecimal = sanitizedInput.substring(firstDecimalIndex + 1).replace(/\./g, '')

    // Limit the number of decimal places to 2
    if (afterDecimal.length > decimals) {
      afterDecimal = afterDecimal.substring(0, decimals)
    }

    sanitizedInput = beforeDecimal + afterDecimal
  }

  // Format the number with commas as thousand separators
  const parts = sanitizedInput.split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return parts.join('.')
}

/**
 * Cleans the input by removing all non-numeric characters except the decimal point.
 * This function is designed to sanitize input values by ensuring that only numeric characters
 * and a single decimal point are retained in the output string. It is useful for processing
 * inputs that should contain only numeric values, such as in forms or data entry fields.
 *
 * @param {string | number | undefined} input - The input value to be cleaned. This can be a string,
 * number, or undefined. If the input is a number or a string containing numeric values, it will
 * be converted to a string for processing. If the input is undefined, an empty string is returned.
 *
 * @returns {string} The cleaned input value as a string, with all non-numeric characters removed,
 * except for a single decimal point. If the input is an empty string or undefined, an empty string
 * is returned.
 *
 * @example
 * // returns "1234.56"
 * cleanInput("1,234.56abc")
 *
 * @example
 * // returns "123456"
 * cleanInput("12, 3456")
 *
 * @example
 * // returns ""
 * cleanInput(undefined)
 */
export const cleanInput = (input: string | number | undefined): string => {
  const inputString = input?.toString() ?? ''

  if (!inputString || inputString === '') return ''
  return inputString.replace(/[^0-9.]/g, '')
}
