import { ColumnDef } from '@tanstack/react-table'
import { Candle } from '@/graphql/generated/graphql-request'
import { createNotification, NotificationType } from '@/utils/notificationUtils'

export const tableColumnsSubscriptionCandles: ColumnDef<Candle>[] = [
  {
    accessorKey: 'timestamp',
    header: () => <div className='text-xs text-left text-white'>Timestamp</div>,
    cell: ({ row }) => {
      const time = row.original.time.toString()
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(time)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Timestamp ${time} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {time}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'date',
    header: () => <div className='text-xs text-left text-white'>Date</div>,
    cell: ({ row }) => {
      const time = row.original.time.toString()
      //show date in format yyyy-mm-dd HH:MM:SS
      const date = new Date(parseInt(time)).toISOString().replace('T', ' ').replace(/\..+/, '')
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(date)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Date ${date} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {date}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'high',
    header: () => <div className='text-xs text-left text-white'>High</div>,
    cell: ({ row }) => {
      const high = row.original.high.toString()
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(high)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `High ${high} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {high}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'low',
    header: () => <div className='text-xs text-left text-white'>Low</div>,
    cell: ({ row }) => {
      const low = row.original.low.toString()
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(low)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Low ${low} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {low}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'open',
    header: () => <div className='text-xs text-left text-white'>Open</div>,
    cell: ({ row }) => {
      const open = row.original.open.toString()
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(open)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Open ${open} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {open}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'close',
    header: () => <div className='text-xs text-left text-white'>close</div>,
    cell: ({ row }) => {
      const close = row.original.close.toString()
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(close)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Close ${close} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {close}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'volume',
    header: () => <div className='text-xs text-left text-white'>Volume</div>,
    cell: ({ row }) => {
      const volume = row.original.volume.toString()
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(volume)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Volume ${volume} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {volume}
        </div>
      )
    },
    size: 300,
  },
]
