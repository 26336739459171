import { redirect } from 'react-router-dom'
import { getAuth } from 'firebase/auth'

export async function authLoader() {
  return new Promise((resolve, reject) => {
    getAuth().onAuthStateChanged(
      (user) => {
        if (user) {
          return resolve(redirect('/'))
        }
        return resolve(false)
      },
      (err) => {
        reject(err)
      },
    )
  })
}
