// src/utils/tradeUtils.ts
import { cleanInput } from '@/utils/inputUtils'
import { MIN_PRICE, MAX_PRICE, MAX_AMOUNT } from '@/utils/constants'
import { OrderSide, TradeTypes } from '@/constants/trade'
import { Summary } from '@/types/TradeFormTypes'
import {
  AdvancedOrderType,
  OrderType,
  CreateAdvancedOrderMutation,
  CreateOrderWithPriceMutation,
  OrderBook,
  Asset,
  Balance,
  UserBalances,
} from '@/graphql/generated/graphql-request'

export type ExecutionOption = {
  type: string
  string: string
}

export enum TradeBoundsState {
  PRICE_MIN_ERROR = 'PRICE_MIN_ERROR',
  PRICE_MAX_ERROR = 'PRICE_MAX_ERROR',
  AMOUNT_MAX_ERROR = 'SIZE_MIN_ERROR',
  NO_ERROR = 'NO_ERROR',
}

export enum TradeTriggerPriceState {
  PRICE_MIN_ERROR = 'PRICE_MIN_ERROR',
  PRICE_MAX_ERROR = 'PRICE_MAX_ERROR',
  NO_ERROR = 'NO_ERROR',
}

export enum TradeLeverageState {
  ERROR = 'ERROR',
  NO_ERROR = 'NO_ERROR',
}

export enum BorrowState {
  ERROR = 'ERROR',
  NO_ERROR = 'NO_ERROR',
}

export type TradeFlags = {
  needsLimitPrice: boolean
  needsTrailingPercent: boolean
  needsTriggerPrice: boolean
  needsGoodUntil: boolean
  needsPostOnly: boolean
  needsReduceOnly: boolean
  needsSize: boolean
  needsAdvancedOptions: boolean
  needsLeverage: boolean
  needsBrackets: boolean
  hasTimeInForce: boolean
  needsExecution: boolean
  executionOptions: ExecutionOption[]
}

export const getTradeFlagsByTradeType = (selectedTradeType: TradeTypes): TradeFlags => {
  switch (selectedTradeType) {
    case TradeTypes.LIMIT:
      return {
        needsLimitPrice: true,
        needsTrailingPercent: false,
        needsTriggerPrice: false,
        needsGoodUntil: true,
        needsPostOnly: false,
        needsReduceOnly: false,
        needsSize: true,
        needsAdvancedOptions: true,
        needsLeverage: true,
        needsBrackets: false,
        needsExecution: false,
        hasTimeInForce: true,
        executionOptions: [],
      }
    case TradeTypes.MARKET:
      return {
        needsLimitPrice: false,
        needsTrailingPercent: false,
        needsTriggerPrice: false,
        needsGoodUntil: false,
        needsPostOnly: false,
        needsReduceOnly: true,
        needsSize: true,
        needsAdvancedOptions: true,
        needsLeverage: true,
        needsBrackets: false,
        needsExecution: true,
        hasTimeInForce: false,
        executionOptions: [],
      }
    case TradeTypes.STOP_LIMIT:
      return {
        needsLimitPrice: true,
        needsTrailingPercent: false,
        needsTriggerPrice: true,
        needsGoodUntil: true,
        needsPostOnly: false,
        needsReduceOnly: false,
        needsSize: true,
        needsAdvancedOptions: true,
        needsLeverage: true,
        needsBrackets: false,
        needsExecution: true,
        hasTimeInForce: false,
        executionOptions: [
          {
            type: 'DEFAULT',
            string: 'Default',
          },
          {
            type: 'POST_ONLY',
            string: 'Post Only',
          },
          {
            type: 'IMMEDIATE_OR_CANCEL',
            string: 'Immediate or Cancel',
          },
          {
            type: 'FILL_OR_KILL',
            string: 'Fill or Kill',
          },
        ],
      }
    case TradeTypes.STOP_MARKET:
      return {
        needsLimitPrice: false,
        needsTrailingPercent: false,
        needsTriggerPrice: true,
        needsGoodUntil: true,
        needsPostOnly: false,
        needsReduceOnly: false,
        needsSize: true,
        needsAdvancedOptions: true,
        needsLeverage: true,
        needsBrackets: false,
        needsExecution: true,
        hasTimeInForce: false,
        executionOptions: [
          {
            type: 'DEFAULT',
            string: 'Default',
          },
          {
            type: 'POST_ONLY',
            string: 'Post Only',
          },
          {
            type: 'IMMEDIATE_OR_CANCEL',
            string: 'Immediate or Cancel',
          },
          {
            type: 'FILL_OR_KILL',
            string: 'Fill or Kill',
          },
        ],
      }
    case TradeTypes.TAKE_PROFIT:
      return {
        needsLimitPrice: true,
        needsTrailingPercent: false,
        needsTriggerPrice: true,
        needsGoodUntil: true,
        needsPostOnly: false,
        needsReduceOnly: false,
        needsSize: true,
        needsAdvancedOptions: true,
        needsLeverage: false,
        needsBrackets: false,
        needsExecution: true,
        hasTimeInForce: false,
        executionOptions: [
          {
            type: 'DEFAULT',
            string: 'Default',
          },
          {
            type: 'POST_ONLY',
            string: 'Post Only',
          },
          {
            type: 'IMMEDIATE_OR_CANCEL',
            string: 'Immediate or Cancel',
          },
          {
            type: 'FILL_OR_KILL',
            string: 'Fill or Kill',
          },
        ],
      }
    case TradeTypes.TAKE_PROFIT_MARKET:
      return {
        needsLimitPrice: false,
        needsTrailingPercent: false,
        needsTriggerPrice: true,
        needsGoodUntil: true,
        needsPostOnly: false,
        needsReduceOnly: false,
        needsSize: true,
        needsAdvancedOptions: true,
        needsLeverage: false,
        needsBrackets: false,
        needsExecution: true,
        hasTimeInForce: false,
        executionOptions: [
          {
            type: 'DEFAULT',
            string: 'Default',
          },
          {
            type: 'POST_ONLY',
            string: 'Post Only',
          },
          {
            type: 'IMMEDIATE_OR_CANCEL',
            string: 'Immediate or Cancel',
          },
          {
            type: 'FILL_OR_KILL',
            string: 'Fill or Kill',
          },
        ],
      }
    case TradeTypes.ORDERBOOK:
      return {
        needsLimitPrice: false,
        needsTrailingPercent: false,
        needsTriggerPrice: false,
        needsGoodUntil: false,
        needsPostOnly: false,
        needsReduceOnly: false,
        needsSize: false,
        needsAdvancedOptions: false,
        needsLeverage: false,
        needsBrackets: false,
        needsExecution: false,
        hasTimeInForce: false,
        executionOptions: [],
      }
    default:
      throw new Error('Invalid trade type')
  }
}

// Define the function overloads
export function getOrderTypeName(selectedTradeType: TradeTypes, selectedOrderSide: OrderSide): string
export function getOrderTypeName(orderType: OrderType): string

/**
 * Retrieves the name of the order type based on the provided trade type and order side or a specific order type.
 * This function supports two forms of input:
 * 1. A combination of `TradeTypes` and `OrderSide` to determine the order name based on trade and side.
 * 2. A single `OrderType` to directly determine the order name.
 *
 * Overloads:
 * - getOrderTypeName(selectedTradeType: TradeTypes, selectedOrderSide: OrderSide): string
 *   Determines the order name based on the trade type (e.g., LIMIT, MARKET) and the order side (BUY, SELL).
 *
 * - getOrderTypeName(orderType: OrderType): string
 *   Determines the order name directly from the order type (e.g., LimitBuy, MarketSell).
 *
 * @param {TradeTypes | OrderType} arg1 - The trade type (if combined with `OrderSide`) or the order type.
 * @param {OrderSide} [arg2] - The order side, required if the first argument is a trade type.
 * @returns {string} The name of the order type, such as 'Limit Buy' or 'Market Sell'.
 * @throws {Error} Throws an error if the arguments do not match expected types or combinations.
 *
 * Examples:
 * - Using trade type and order side:
 *   getOrderTypeName(TradeTypes.LIMIT, OrderSide.BUY) // Returns 'Limit Buy'
 *
 * - Using order type:
 *   getOrderTypeName(OrderType.MarketSell) // Returns 'Market Sell'
 */
export function getOrderTypeName(arg1: TradeTypes | OrderType, arg2?: OrderSide): string {
  if (typeof arg1 === 'string' && arg2 === undefined) {
    switch (arg1) {
      case OrderType.LimitBuy:
        return 'Limit Buy'
      case OrderType.LimitSell:
        return 'Limit Sell'
      case OrderType.MarketBuy:
        return 'Market Buy'
      case OrderType.MarketSell:
        return 'Market Sell'
      default:
        throw new Error('Invalid order type')
    }
  } else if (typeof arg1 === 'string' && typeof arg2 === 'string') {
    return arg1 === TradeTypes.LIMIT
      ? arg2 === OrderSide.BUY
        ? 'Limit Buy'
        : 'Limit Sell'
      : arg2 === OrderSide.BUY
        ? 'Market Buy'
        : 'Market Sell'
  } else {
    throw new Error('Invalid arguments')
  }
}

/** Determine the order type based on the selected trade type and order side.
 If the selected trade type is LIMIT:
 - If the order side is BUY, the order type is set to LimitBuy.
 - If the order side is not BUY (i.e., SELL), the order type is set to LimitSell.
 If the selected trade type is not LIMIT (i.e., MARKET):
 - If the order side is BUY, the order type is set to MarketBuy.
 - If the order side is not BUY (i.e., SELL), the order type is set to MarketSell.
 **/
export const getOrderType = (selectedTradeType: TradeTypes, selectedOrderSide: OrderSide) => {
  return selectedTradeType === TradeTypes.LIMIT ||
    selectedTradeType === TradeTypes.STOP_LIMIT ||
    selectedTradeType === TradeTypes.TAKE_PROFIT
    ? selectedOrderSide === OrderSide.BUY
      ? OrderType.LimitBuy
      : OrderType.LimitSell
    : selectedOrderSide === OrderSide.BUY
      ? OrderType.MarketBuy
      : OrderType.MarketSell
}

export const getAdvanceTradeType = (selectedTradeType: TradeTypes): AdvancedOrderType => {
  switch (selectedTradeType) {
    case TradeTypes.LIMIT:
    case TradeTypes.MARKET:
      return AdvancedOrderType.Normal
    case TradeTypes.STOP_LIMIT:
    case TradeTypes.STOP_MARKET:
      return AdvancedOrderType.Stop
    case TradeTypes.TAKE_PROFIT:
    case TradeTypes.TAKE_PROFIT_MARKET:
      return AdvancedOrderType.TakeProfit
    default:
      throw new Error('Invalid trade type')
  }
}

export const getAssets = (orderBooks: OrderBook[], orderBookId: string, allAssets: Asset[]) => {
  const orderBook = orderBooks.find((book) => book.orderBookID === orderBookId)
  const base = allAssets.find((asset) => asset.uid === orderBook?.baseUID)
  const quote = allAssets.find((asset) => asset.uid === orderBook?.quoteUID)
  return { baseAsset: base, quoteAsset: quote }
}

export const isCreateOrderWithPriceMutation = (
  response: CreateOrderWithPriceMutation | CreateAdvancedOrderMutation,
): response is CreateOrderWithPriceMutation => {
  return (response as CreateOrderWithPriceMutation).CreateOrderWithPrice !== undefined
}

export const isCreateAdvancedOrderMutation = (
  response: CreateOrderWithPriceMutation | CreateAdvancedOrderMutation,
): response is CreateAdvancedOrderMutation => {
  return (response as CreateAdvancedOrderMutation).CreateAdvancedOrder !== undefined
}

export const validateLimitPriceIsInBounds = (
  selectedTradeType: TradeTypes,
  getValues: (field: string) => string,
  setPriceBoundsState: (state: TradeBoundsState) => void,
) => {
  const amount = Number(cleanInput(getValues('tradeSize')))

  if (
    selectedTradeType === TradeTypes.LIMIT ||
    selectedTradeType === TradeTypes.STOP_LIMIT ||
    selectedTradeType === TradeTypes.TAKE_PROFIT
  ) {
    const price = Number(cleanInput(getValues('limitPrice')))

    if (price === 0 || amount === 0) {
      setPriceBoundsState(TradeBoundsState.NO_ERROR)
      return
    }

    if (price < MIN_PRICE) {
      setPriceBoundsState(TradeBoundsState.PRICE_MIN_ERROR)
      return
    }

    if (price > MAX_PRICE) {
      setPriceBoundsState(TradeBoundsState.PRICE_MAX_ERROR)
      return
    }
  }

  if (amount > 0 && amount > MAX_AMOUNT) {
    setPriceBoundsState(TradeBoundsState.AMOUNT_MAX_ERROR)
    return
  }

  setPriceBoundsState(TradeBoundsState.NO_ERROR)
}

export const validateTriggerPrice = (
  selectedTradeType: TradeTypes,
  getValues: (field: string) => string,
  setTriggerPriceError: (state: TradeTriggerPriceState) => void,
  selectedOrderSide: OrderSide,
  marketPrice: number,
  needsTriggerPrice: boolean,
) => {
  const triggerPrice = Number(cleanInput(getValues('triggerPrice')))

  if (
    !needsTriggerPrice ||
    triggerPrice === 0 ||
    selectedTradeType === TradeTypes.LIMIT ||
    selectedTradeType === TradeTypes.MARKET
  ) {
    setTriggerPriceError(TradeTriggerPriceState.NO_ERROR)
    return
  }

  if (selectedOrderSide === OrderSide.BUY && triggerPrice <= marketPrice) {
    setTriggerPriceError(TradeTriggerPriceState.PRICE_MIN_ERROR)
    return
  }
  if (selectedOrderSide === OrderSide.SELL && triggerPrice >= marketPrice) {
    setTriggerPriceError(TradeTriggerPriceState.PRICE_MAX_ERROR)
    return
  }

  setTriggerPriceError(TradeTriggerPriceState.NO_ERROR)
}

export const validateUserHasEnoughBalance = (
  summary: Summary | undefined,
  selectedOrderSide: OrderSide,
  availableUserBalance: number,
  availableUserCollateralBalance: number,
  getValues: (field: string) => string,
  setHasEnoughBalance: (hasBalance: boolean) => void,
  leverageValue: string,
  selectedTradeType: TradeTypes,
) => {
  const amountToConsider =
    selectedOrderSide === OrderSide.BUY
      ? (summary?.userFunds ?? 0)
      : Number(summary?.size ?? 0) * (1 / Number(leverageValue))

  const hasTotalOrSize = amountToConsider > 0 && (summary?.total ?? 0) > 0
  const isTotalOrSizeLessThanBalance =
    amountToConsider <= availableUserBalance ||
    (selectedOrderSide === OrderSide.SELL && amountToConsider <= availableUserCollateralBalance)

  const hasSize = Number(cleanInput(getValues('tradeSize'))) > 0
  const hasPrice =
    selectedTradeType === TradeTypes.STOP_LIMIT ||
    selectedTradeType === TradeTypes.TAKE_PROFIT ||
    selectedTradeType === TradeTypes.LIMIT ||
    Number(cleanInput(getValues('tradeSizeInCurrency'))) > 0 // limit orders do not have this input
  const hasBalance = hasTotalOrSize && isTotalOrSizeLessThanBalance && hasSize && hasPrice

  setHasEnoughBalance(hasBalance)
}

export const getAssetBalance = (allAssets: Asset[], asset: Asset, balances: UserBalances, selectedIsin: string) => {
  let available = 0

  //With stablecoin equivalence - 1 USD = 1 USDC = 1 USDY.
  if (asset?.symbol === 'USDY' || asset?.symbol === 'USDC' || asset?.symbol === 'USD') {
    const usdcAsset = allAssets.find((asset) => asset.symbol === 'USDC')
    const usdAsset = allAssets.find((asset) => asset.symbol === 'USD')
    const usdcyAsset = allAssets.find((asset) => asset.symbol === 'USDY')

    const usdcyBalance =
      Number(balances?.available?.find((balance) => balance.assetID === 'USDY')?.amount || 0) /
      Math.pow(10, usdcyAsset?.decimals || 0)
    const usdBalance =
      Number(balances?.available?.find((balance) => balance.assetID === 'USD')?.amount || 0) /
      Math.pow(10, usdAsset?.decimals || 0)
    const usdcBalance =
      Number(balances?.available?.find((balance) => balance.assetID === 'USDC')?.amount || 0) /
      Math.pow(10, usdcAsset?.decimals || 0)

    available += usdcyBalance + usdBalance + usdcBalance
  } else {
    available =
      (Number(balances?.available?.find((balance) => balance.assetID === selectedIsin)?.amount) ?? 0) /
      Math.pow(10, asset?.decimals ?? 0)
  }

  if (Number.isNaN(available)) {
    return 0
  }

  return available
}

export const createSummary = (
  marketPrice: number,
  availableUserBalance: number,
  availableUserCollateralBalance: number,
  selectedTradeType: TradeTypes,
  getValues: (field: string) => string,
  setSummary: (summary: Summary) => void,
  priceDisplayMultiplier: number,
  selectedOrderSide: OrderSide,
  leverageValue: string,
  setTriggerPriceError: (state: TradeTriggerPriceState) => void,
  setPriceBoundsState: (state: TradeBoundsState) => void,
  setHasEnoughBalance: (hasBalance: boolean) => void,
  summary: Summary | undefined,
) => {
  const limitPrice = Number(cleanInput(getValues('limitPrice'))) ?? 0
  const amountSize = Number(cleanInput(getValues('tradeSize'))) ?? 0
  const amountPrice = Number(cleanInput(getValues('tradeSizeInCurrency'))) ?? 0

  let price = 0
  switch (selectedTradeType) {
    case TradeTypes.LIMIT:
    case TradeTypes.STOP_LIMIT:
    case TradeTypes.TAKE_PROFIT:
      price = limitPrice ?? 0
      break
    case TradeTypes.MARKET:
    case TradeTypes.STOP_MARKET:
    case TradeTypes.TAKE_PROFIT_MARKET:
      price = amountPrice ?? 0
      break
    default:
      break
  }

  const total = amountSize * price
  const totalDisplay = total / priceDisplayMultiplier
  const borrow =
    selectedOrderSide === OrderSide.BUY
      ? Number(leverageValue) > 0
        ? totalDisplay * (1 - 1 / Number(leverageValue))
        : 0
      : Number(leverageValue) > 0
        ? amountSize * (1 - 1 / Number(leverageValue))
        : 0
  const userFunds =
    selectedOrderSide === OrderSide.BUY
      ? Number(leverageValue) > 0
        ? totalDisplay * (1 / Number(leverageValue))
        : 0
      : Number(leverageValue) > 0
        ? amountSize * (1 / Number(leverageValue))
        : 0

  summary = {
    price,
    size: amountSize ?? 0,
    totalDisplay,
    total,
    borrow,
    userFunds,
  }

  setSummary(summary)
  validateTriggerPrice(selectedTradeType, getValues, setTriggerPriceError, selectedOrderSide, marketPrice, true)
  validateLimitPriceIsInBounds(selectedTradeType, getValues, setPriceBoundsState)
  validateUserHasEnoughBalance(
    summary,
    selectedOrderSide,
    availableUserBalance,
    availableUserCollateralBalance,
    getValues,
    setHasEnoughBalance,
    leverageValue,
    selectedTradeType,
  )
}
