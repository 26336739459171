import { useOrderBots } from '@/hooks/useOrderBots'
import { useMemo } from 'react'

interface OrderBotCardProps {
  botID: string
}

export const OrderBotCard = ({ botID }: OrderBotCardProps) => {
  const { orderBots } = useOrderBots()
  const bot = useMemo(() => orderBots.find((bot) => bot.botID === botID), [orderBots, botID])

  if (!bot) {
    return <div>OrderBot not found</div>
  }

  return (
    <div className='shadow-md rounded-lg p-2 bg-transparent'>
      <h1 className='text-xl font-bold mb-4 text-primary'>Order Bot</h1>
      <h1 className='text-xl font-bold mb-4'>{bot.name}</h1>
      <div className='flex flex-row justify-between gap-4'>
        <div>Amount List:</div>
        <div>{bot.amountList.join(', ')}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Base Decimals:</div>
        <div>{bot.baseDecimals}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Created At:</div>
        <div>{new Date(bot.createdAt).toLocaleString()}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Custom Tick:</div>
        <div>{bot.customTick}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Frequency:</div>
        <div>{bot.frequency}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Levels:</div>
        <div>{bot.levels}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Max Mult:</div>
        <div>{bot.maxMult}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Min Mult:</div>
        <div>{bot.minMult}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Order Book ID:</div>
        <div>{bot.orderBookID}</div>
      </div>
      <div className='flex flex-row justify-between gap-4'>
        <div>Price List:</div>
        <div>{bot.priceList.join(', ')}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Quote Decimals:</div>
        <div>{bot.quoteDecimals}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Spread:</div>
        <div>{bot.spread}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Starting Base Amount:</div>
        <div>{bot.startingBaseAmount}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Strategy:</div>
        <div>{bot.strategy}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Updated At:</div>
        <div>{new Date(bot.updatedAt).toLocaleString()}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Updated By:</div>
        <div>{bot.updatedBy}</div>
      </div>
    </div>
  )
}
