import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import { useAllAssets } from '@/hooks/useAsset'
import { DataTableProps } from '@/types/Table'
import { usePools } from '@/hooks/usePools'

export function TableAdministrationPools<TData, TValue>({ columns, data }: DataTableProps<TData, TValue>) {
  const { allAssets } = useAllAssets()

  const getPoolSymbols = (poolUid: string) => {
    const { allPools } = usePools()
    const pool = allPools.find((pool) => pool.uid === poolUid)
    const baseAsset = allAssets.find((asset) => asset.uid === pool?.baseAsset?.assetID)
    const quoteAsset = allAssets.find((asset) => asset.uid === pool?.quoteAsset?.assetID)

    return `${baseAsset?.symbol} - ${quoteAsset?.symbol}`
  }

  const getAsset = (assetID: string) => {
    return allAssets.find((asset) => asset.uid === assetID)
  }

  const table = useReactTable({
    data,
    columns,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      getPoolSymbols,
      getAsset,
    },
  })

  return (
    <div className='w-full panel dark:bg-card flex flex-col h-full'>
      <Table>
        <TableHeader className='text-sm'>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className='p-1 ' key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    className='dark:bg-dark-darker border-b border-b-dark'
                    key={header.id}
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow className='p-1' key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell className={`border-b border-dark`} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className='border p-1'>
              <TableCell colSpan={columns.length} className='text-center'>
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className='flex items-center justify-end space-x-2 py-4'>
        <Button variant='outline' size='sm' onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Previous
        </Button>
        <div className={'text-xs'}>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <Button variant='outline' size='sm' onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          Next
        </Button>
      </div>
    </div>
  )
}
