import { ColumnDef } from '@tanstack/react-table'
import { Pool } from '@/graphql/generated/graphql-request'
import { createNotification, NotificationType } from '@/utils/notificationUtils'
import { formatNumber } from '@/utils/numberUtils'
import { PRICE_DECIMALS } from '@/utils/constants'

export const tableColumnsFunds: ColumnDef<Pool>[] = [
  {
    accessorKey: 'uid',
    header: () => <div className='text-xs text-left text-white'>UID</div>,
    cell: ({ row, table }) => {
      const uid = table?.options?.meta?.getPoolSymbols
        ? table?.options?.meta?.getPoolSymbols(row.original.uid)
        : row.original.uid
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(uid)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `UID ${uid} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {uid}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'Assets',
    header: () => <div className='text-xs text-left text-white font-semibold'>Assets</div>,
    cell: ({ row, table }) => {
      const assetBalances = [row.original.baseAsset, row.original.quoteAsset]

      return (
        <div>
          {assetBalances.map((assetBalance, index) => {
            const assetDecimals = table?.options?.meta?.getAsset
              ? (table?.options?.meta?.getAsset(assetBalance?.assetID)?.decimals ?? 0)
              : 0
            const amount = Number.isNaN(assetBalance?.amount) ? 0 : Number(assetBalance?.amount)

            return (
              <div key={'col_pool_' + assetBalance.assetID} className='flex flex-col'>
                <div className='flex flex-row justify-between gap-4 items'>
                  <div className='w-2/3 flex flex-row items-center justify-start gap-2'>
                    <div className='text-primary items-start align-bottom font-semibold'>AssetID</div>
                    <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                      {assetBalance?.assetID}
                    </div>
                  </div>
                  <div className='w-1/3 flex flex-row items-center justify-start gap-2'>
                    <div className='text-primary items-start align-bottom font-semibold'>Amount</div>
                    <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                      {formatNumber(amount / Math.pow(10, assetDecimals), PRICE_DECIMALS)}
                    </div>
                  </div>
                </div>
                {index === 0 && <div className='w-full flex flex-row justify-center font-semibold p-2' />}
              </div>
            )
          })}
        </div>
      )
    },
    size: 400,
  },
  {
    accessorKey: 'type',
    header: () => <div className='text-xs text-left text-white'>Type</div>,
    cell: ({ row }) => {
      const type = row.original.type
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(type)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Type ${type} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {type}
        </div>
      )
    },
    size: 200,
  },
  {
    accessorKey: 'totalLiquidityShares',
    header: () => <div className='text-xs text-left text-white'>Liquidity</div>,
    cell: ({ row, table }) => {
      const poolDecimals = table?.options?.meta?.getAsset
        ? (table?.options?.meta?.getAsset(row.original.uid)?.decimals ?? 0)
        : 0

      const amount = Number.isNaN(row.original.poolShares.amount) ? 0 : Number(row.original.poolShares.amount)
      const liquidity = formatNumber(amount / Math.pow(10, poolDecimals), PRICE_DECIMALS)

      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(liquidity)
              .finally(() =>
                createNotification(
                  'Copied ',
                  `Liquidity ${liquidity} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {liquidity}
        </div>
      )
    },
    size: 300,
  },
]
