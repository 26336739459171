import { createSlice } from '@reduxjs/toolkit'
import { Candle } from '@/graphql/generated/graphql-request'

// Step 2: Define the initial state
const initialState = {
  candles: {} as Record<string, Candle[]>,
  aggregatedCandles: {} as Record<string, Candle[]>,
}

// Step 3: Create the slice
const candleSlice = createSlice({
  name: 'candles',
  initialState,
  reducers: {
    // Define a reducer to update LastChartPrice
    setCandles(state, action) {
      try {
        const oldState = { ...state.candles }
        const oldCandles = oldState[action.payload.ticker] || []

        // store all Candles
        state.candles[action.payload.ticker] = [...action.payload.candles, ...oldCandles]

        // store aggregated candles

        const oldAggregatedState = { ...state.aggregatedCandles }
        const oldAggregatedCandles = oldAggregatedState[action.payload.ticker] || []

        // new object with all new candles, and old candles, where if there are new candles for the same time, they are replaced
        // add new candles to the state but keep old ones
        state.aggregatedCandles[action.payload.ticker] = [
          ...action.payload.candles,
          ...oldAggregatedCandles.filter((oldCandle) => {
            return !action.payload.candles.some((candle: Candle) => candle.time === oldCandle.time)
          }),
        ]
      } catch (e) {
        console.log('Error setting candles', e)
      }
    },
    clearAllCandles(state) {
      state.candles = {}
      state.aggregatedCandles = {}
    },
  },
})

// Step 4: Export the actions and reducer
export const { setCandles, clearAllCandles } = candleSlice.actions
export default candleSlice.reducer
