import { FormEvent, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Asset, AssetType, getSdk } from '@/graphql/generated/graphql-request'
import { removeLettersForInput } from '@/utils/inputUtils'
import { useAllAssets } from '@/hooks/useAsset'
import { useMutation } from '@tanstack/react-query'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { queryClient } from '@/services/api'

type Inputs = {
  baseAsset: string
  quoteAsset: string
  g: string
  poolMaturity: string
  priceDisplayMultiplier: string
  tickSize: string
}

export const CreateLiquidityPoolForm = () => {
  const { allAssets } = useAllAssets()
  const [selectedbaseAsset, setSelectedbaseAsset] = useState(allAssets[0]?.symbol ?? '')
  const [selectedquoteAsset, setSelectedquoteAsset] = useState(allAssets[1]?.symbol ?? '')

  const nonPoolAssets = allAssets.filter((asset: Asset) => asset?.type !== AssetType.PoolShare)

  const validationSchema = z
    .object({
      baseAsset: z.string().min(1, 'Please select a bond'),
      quoteAsset: z.string().min(1, 'Please select a bond'),
      g: z.string().min(1, 'Please insert a value for g'),
      poolMaturity: z.string().min(1, 'Please insert a value for pool maturity'),
      priceDisplayMultiplier: z.string().min(1, 'Please insert a value for price display multiplier'),
      tickSize: z.string().min(1, 'Please insert a value for tick size'),
    })
    .refine((data) => data.baseAsset !== data.quoteAsset, {
      message: 'Please select different bonds',
      path: ['quoteAsset'],
    })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(validationSchema),
  })

  const handleInputChange = (e: FormEvent<HTMLInputElement>, field: keyof Inputs) => {
    const inputValue = e.currentTarget.value
    const sanitizedInput = removeLettersForInput(inputValue)
    setValue(field, sanitizedInput)
  }

  const mutation = useMutation({
    mutationFn: ({
      baseAssetID,
      quoteAssetID,
      g,
      poolMaturity,
      priceDisplayMultiplier,
      tickSize,
    }: {
      baseAssetID: string
      quoteAssetID: string
      g: string
      poolMaturity: string
      priceDisplayMultiplier: string
      tickSize: string
    }) =>
      getSdk(graphQLClient)
        .CreateOrderBook({
          input: {
            ammInput: {
              g: Number(g),
              poolMaturity: Number(poolMaturity),
            },
            baseUID: baseAssetID,
            priceDisplayMultiplier: Number(priceDisplayMultiplier),
            quoteUID: quoteAssetID,
            tickSize: Number(tickSize),
          },
        })
        .then(() => {
          createNotification(
            'Create Pool ',
            {
              baseAssetID,
              quoteAssetID,
            },
            NotificationType.CREATE_POOL,
          )
        })
        .catch((err) => {
          const message = err?.message ?? 'Error on CreatePool'
          createNotification('Error on CreatePool', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['POOLS'] }).finally()
      queryClient.invalidateQueries({ queryKey: ['ASSETS'] }).finally()
    },
  })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const payload = {
      baseAssetID: data.baseAsset,
      quoteAssetID: data.quoteAsset,
      g: data.g,
      poolMaturity: data.poolMaturity,
      priceDisplayMultiplier: data.priceDisplayMultiplier,
      tickSize: data.tickSize,
    }

    console.log('create pool payload', payload)
    mutation.mutate(payload)
  }

  return (
    <div className='w-96'>
      <div className='panel grid grid-cols-1 dark:bg-card h-full items-center'>
        <h1 className='col-span-1 dark:text-white text-lg font-semibold pb-8'>Create OrderBook</h1>
        <form onSubmit={handleSubmit(onSubmit)} className='w-full col-span-1 grid grid-cols-2 gap-4'>
          <div className='flex flex-col'>
            <label className='text-left'>Base Asset</label>
            <select
              {...register('baseAsset', { required: true })}
              value={selectedbaseAsset}
              onChange={(e) => setSelectedbaseAsset(e.target.value)}
              className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
            >
              {nonPoolAssets.map((asset: Asset) => (
                <option key={'create_pool_option_first' + asset.uid} value={asset?.uid}>
                  {asset?.symbol}
                </option>
              ))}
            </select>
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.baseAsset ? errors.baseAsset.message : '\u00a0'}
            </span>
          </div>

          <div className='flex flex-col'>
            <label className='text-left'>Quote Asset</label>
            <select
              {...register('quoteAsset', { required: true })}
              value={selectedquoteAsset}
              onChange={(e) => setSelectedquoteAsset(e.target.value)}
              className='border !border-dark rounded-sm shadow-sm appearance-none form-select peer bg-card placeholder:tracking-widest valid:border-dark'
            >
              {nonPoolAssets.map((asset: Asset) => (
                <option key={'create_pool_option_second' + asset.uid} value={asset?.uid}>
                  {asset?.symbol}
                </option>
              ))}
            </select>
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.quoteAsset ? errors.quoteAsset.message : '\u00a0'}
            </span>
          </div>

          <div className='flex flex-col'>
            <label className='text-left'>Pool Maturity</label>
            <input
              id='poolMaturity'
              {...register('poolMaturity', { required: true })}
              onInput={(e) => handleInputChange(e, 'poolMaturity')}
              className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
            />
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.poolMaturity ? errors.poolMaturity.message : '\u00a0'}
            </span>
          </div>

          <div className='flex flex-col'>
            <label className='text-left'>Price Display Multiplier</label>
            <input
              id='priceDisplayMultiplier'
              {...register('priceDisplayMultiplier', { required: true })}
              onInput={(e) => handleInputChange(e, 'priceDisplayMultiplier')}
              className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
            />
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.priceDisplayMultiplier ? errors.priceDisplayMultiplier.message : '\u00a0'}
            </span>
          </div>

          <div className='flex flex-col'>
            <label className='text-left'>G</label>
            <input
              id='g'
              {...register('g', { required: true })}
              onInput={(e) => handleInputChange(e, 'g')}
              className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
            />
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.g ? errors.g.message : '\u00a0'}
            </span>
          </div>

          <div className='flex flex-col'>
            <label className='text-left'>Tick Size</label>
            <input
              id='tickSize'
              {...register('tickSize', { required: true })}
              onInput={(e) => handleInputChange(e, 'tickSize')}
              className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
            />
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.tickSize ? errors.tickSize.message : '\u00a0'}
            </span>
          </div>

          <div className='col-span-2 flex items-center'>
            <button
              type='submit'
              className='w-full btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] my-4'
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
