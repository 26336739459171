import { AlertType } from '@/constants/alerts'
import { TimeUnitShort } from '@/constants/time'

// TODO: rename to OrderType
export enum TradeTypes {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
  STOP_LIMIT = 'STOP_LIMIT',
  STOP_MARKET = 'STOP_MARKET',
  TAKE_PROFIT = 'TAKE_PROFIT',
  TAKE_PROFIT_MARKET = 'TAKE_PROFIT_MARKET',
  TRAILING_STOP = 'TRAILING_STOP',
  ORDERBOOK = 'ORDERBOOK',
}

export enum OrderSide {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum PositionSide {
  None = 'NONE',
  Long = 'LONG',
  Short = 'SHORT',
}

export const GOOD_TIL_TIME_TIMESCALE_STRINGS: Record<TimeUnitShort, string> = {
  [TimeUnitShort.Minutes]: 'Minutes',
  [TimeUnitShort.Hours]: 'Hours',
  [TimeUnitShort.Days]: 'Days',
  [TimeUnitShort.Weeks]: 'Weeks',
}

export enum TradeBoxKeys {
  LimitPrice = 'price.limitPrice',
  TrailingPercent = 'price.trailingPercent',
  TriggerPrice = 'price.triggerPrice',
}

export type InputErrorData = {
  attached: boolean
  message: string
  type: AlertType
}

export enum MobilePlaceOrderSteps {
  EditOrder = 'EditOrder',
  PreviewOrder = 'PreviewOrder',
  PlacingOrder = 'PlacingOrder',
  Confirmation = 'Confirmation',
}
