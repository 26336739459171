import { UseQueryResult } from '@tanstack/react-query'
import { useMemo, useRef, useState } from 'react'
import { getApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'

const app = getApp()
const analytics = getAnalytics(app)
/**
 * Get duration of current query in milliseconds
 * Returns `Infinity` while loading
 */
export function useQueryTime(queryKey: string, status: UseQueryResult['status']): number {
  const lastKeyRef = useRef('__initial')
  const [interval, setInterval] = useState<[begin: number, end: number]>([0, Infinity])

  return useMemo(() => {
    if (lastKeyRef.current !== queryKey) {
      lastKeyRef.current = queryKey
      setInterval([0, Infinity])
    }

    const [begin, end] = interval

    if (begin === 0 && status === 'pending') {
      setInterval(([, end]) => [performance.now(), end])
    }

    if (!Number.isFinite(end) && ['error', 'success'].includes(status)) {
      setInterval(([begin]) => [begin, performance.now()])
    }
    logEvent(analytics, 'useQueryTime', {
      lastKeyRef: lastKeyRef.current,
      status: status,
      interval: interval,
    })
    return end - begin
  }, [interval, status, queryKey])
}
