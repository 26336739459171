import { useSelector } from 'react-redux'
import { IRootState } from '@/store'
import { Candle } from '@/graphql/generated/graphql-request'
import { Separator } from '@/components/ui/separator'
import { WithTooltip } from '@/components/orders/WithTooltip'
import { Switch } from '@/components/ui/switch'
import { Label } from '@radix-ui/react-label'
import { TableSubscriptionCandles } from '@/components/administration/TableSubscriptionCandles'
import { useOrderBooks } from '@/hooks/useOrderBooks'
import { useMemo, useState } from 'react'

export const CandlesPanel = () => {
  const { orderBooks } = useOrderBooks()
  const [isAggregatedCandles, setIsAggregatedCandles] = useState<Record<string, boolean>>({})
  const allCandles = useSelector((state: IRootState) => state.candles.candles) as Record<string, Candle[]>
  const aggregatedCandles = useSelector((state: IRootState) => state.candles.aggregatedCandles) as Record<
    string,
    Candle[]
  >

  const candleData = useMemo(() => {
    const data: Record<string, Candle[]> = {}

    orderBooks.forEach((orderBook) => {
      const orderBookID = orderBook.orderBookID
      if (isAggregatedCandles[orderBookID]) {
        data[orderBookID] = aggregatedCandles[orderBookID] || []
      } else {
        data[orderBookID] = allCandles[orderBookID] || []
      }
    })

    return data
  }, [
    JSON.stringify(isAggregatedCandles),
    JSON.stringify(allCandles),
    JSON.stringify(aggregatedCandles),
    JSON.stringify(orderBooks),
  ])

  const toggleAggregatedCandles = (orderBookID: string) => {
    setIsAggregatedCandles((prevState) => ({
      ...prevState,
      [orderBookID]: !prevState[orderBookID],
    }))
  }

  return (
    <div className={'w-full pb-8'}>
      <div className={'w-full text-xl font-semibold justify-start mt-2 text-primary'}>Candles</div>
      <Separator className={'mb-2'} />
      {orderBooks &&
        orderBooks.map((orderBook) => {
          const data = candleData[orderBook.orderBookID]
          if (!data) return null
          return (
            <div key={orderBook.orderBookID} className={'w-full'}>
              <div className={'w-full flex flex-row justify-between'}>
                <div className={'w-full text-sm font-semibold justify-start mt-2 text-primary'}>
                  OrderBook: {orderBook.orderBookID}
                </div>

                <div className={'w-full flex flex-row gap-2 items-center text-center justify-center'}>
                  <div className={'flex flex-row'}>
                    <div>Total Candles:</div>
                    <div className={'text-primary'}>
                      {allCandles[orderBook.orderBookID] ? allCandles[orderBook.orderBookID].length : 0}
                    </div>
                  </div>
                  <div className={'flex flex-row'}>
                    <div>Aggregated Candles:</div>
                    <div className={'text-primary'}>
                      {aggregatedCandles[orderBook.orderBookID] ? aggregatedCandles[orderBook.orderBookID].length : 0}
                    </div>
                  </div>
                </div>

                <div
                  className={
                    'w-full flex flex-row  justify-end text-sm font-semibold mt-2 text-primary text-end items-end'
                  }
                >
                  <div className='flex items-center space-x-2'>
                    <WithTooltip tooltip='aggregated-candles' side='top' cursor={'pointer'}>
                      <Switch
                        id={`aggregated-candles-${orderBook.orderBookID}`}
                        checked={isAggregatedCandles[orderBook.orderBookID] || false}
                        onClick={() => toggleAggregatedCandles(orderBook.orderBookID)}
                      />
                      <Label htmlFor='airplane-mode'>Aggregated Candles</Label>
                    </WithTooltip>
                  </div>
                </div>
              </div>
              <TableSubscriptionCandles data={data} />
            </div>
          )
        })}
    </div>
  )
}
