import { useQuery } from '@tanstack/react-query'
import { getSdk, ModulePositions } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { useQueryTime } from '@/hooks/useQueryTime'

export const useModulePositions = () => {
  const {
    isLoading: loadingModulePositions,
    data: modulePositions,
    status,
  } = useQuery({
    queryKey: ['MODULE_POSITIONS'],
    queryFn: async () =>
      getSdk(graphQLClient)
        .ModulePositions()
        .then((result) => result.modulePositions as ModulePositions)
        .catch((err) => {
          const message = err?.message ?? 'Error on ModulePositions'
          createNotification('Error on ModulePositions', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    placeholderData: {
      balance: [],
      supplied: [],
      borrowed: [],
      virtual: [],
    },
  })
  useQueryTime(`['MODULE_POSITIONS'}]`, status)
  return {
    loadingModulePositions,
    modulePositions: modulePositions ?? {
      balance: [],
      supplied: [],
      borrowed: [],
      virtual: [],
    },
  }
}
