import { ColumnDef } from '@tanstack/react-table'
import { TraderBot } from '@/graphql/generated/graphql-request'
import { createNotification, NotificationType } from '@/utils/notificationUtils'
import { FiEdit } from 'react-icons/fi'
import dayjs from 'dayjs'
import { IoPlaySharp, IoStopSharp } from 'react-icons/io5'

export const tableColumnsTraderBots: ColumnDef<TraderBot>[] = [
  {
    accessorKey: 'date',
    header: () => <div className='text-xs text-left text-white'>Update at</div>,
    cell: ({ row }) => {
      //show date in format yyyy-mm-dd HH:MM:SS
      const date = dayjs(row.original.updatedAt * 1000).format('YYYY-MM-DD HH:mm:ss')
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(date)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Date ${date} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {date}
        </div>
      )
    },
    size: 75,
  },
  {
    accessorKey: 'botID',
    header: () => <div className='text-xs text-left text-white'>BotID</div>,
    cell: ({ row }) => {
      const botID = row.original.botID
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(botID)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `BotID ${botID} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {botID}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'name',
    header: () => <div className='text-xs text-left text-white'>Name</div>,
    cell: ({ row }) => {
      const name = row.original.name
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(name)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Bot Name ${name} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {name}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'orderBookID',
    header: () => <div className='text-xs text-left text-white'>OrderBookID</div>,
    cell: ({ row }) => {
      const orderBookID = row.original.orderBookID
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(orderBookID)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `OrderBookID ${orderBookID} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {orderBookID}
        </div>
      )
    },
    size: 75,
  },
  {
    accessorKey: 'interval',
    header: () => <div className='text-xs text-left text-white'>Interval</div>,
    cell: ({ row }) => {
      const interval = row.original.orderInterval
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(interval.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Order Interval ${interval} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {interval}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'levels',
    header: () => <div className='text-xs text-left text-white'>Levels</div>,
    cell: ({ row }) => {
      const levels = row.original.levels
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(levels.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Levels ${levels} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {levels}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'minSwap',
    header: () => <div className='text-xs text-left text-white'>Min Swap</div>,
    cell: ({ row }) => {
      const minSwap = row.original.minSwapAmount
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(minSwap.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Min Swap ${minSwap} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {minSwap}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'maxSwap',
    header: () => <div className='text-xs text-left text-white'>Max Swap</div>,
    cell: ({ row }) => {
      const maxSwap = row.original.maxSwapAmount
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(maxSwap.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Max Swap ${maxSwap} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {maxSwap}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'strategyName',
    header: () => <div className='text-xs text-left text-white'>Strategy</div>,
    cell: ({ row }) => {
      const strategy = row.original.strategyName
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(strategy.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Strategy ${strategy} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {strategy}
        </div>
      )
    },
    size: 75,
  },
  {
    accessorKey: 'orderQty',
    header: () => <div className='text-xs text-left text-white'>Order Qty</div>,
    cell: ({ row }) => {
      const orderQty = row.original.orderQuantity
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(orderQty.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Order Qty ${orderQty} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {orderQty}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'swapQty',
    header: () => <div className='text-xs text-left text-white'>Swap Qty</div>,
    cell: ({ row }) => {
      const swapQty = row.original.swapQuantity
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(swapQty.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Swap Qty ${swapQty} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {swapQty}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'dps',
    header: () => <div className='text-xs text-left text-white'>DPS</div>,
    cell: ({ row }) => {
      const dps = row.original.dps
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(dps.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `DPS ${dps} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {dps}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'targetPrice',
    header: () => <div className='text-xs text-left text-white'>Target Price</div>,
    cell: ({ row }) => {
      const targetPrice = row.original.targetPrice
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(targetPrice.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Target Price ${targetPrice} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {targetPrice}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'amountMult',
    header: () => <div className='text-xs text-left text-white'>Amt. Mult</div>,
    cell: ({ row }) => {
      const amtMult = row.original.amountMult
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(amtMult.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Amount Mult ${amtMult} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {amtMult}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'amountList',
    header: () => <div className='text-xs text-left text-white'>Amount List</div>,
    cell: ({ row }) => {
      const amountList = row.original.amountList.join(', ')
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(amountList.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Price List ${amountList} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {amountList}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'spread',
    header: () => <div className='text-xs text-left text-white'>Spread</div>,
    cell: ({ row }) => {
      const spread = row.original.spread
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(spread.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Spread ${spread} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {spread}
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'status',
    header: () => <div className='text-xs text-left text-white'>Status</div>,
    cell: ({ row }) => {
      const status = 'FUTURE FEATURE' //row.original.status
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(status.toString())
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Status ${status} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {status}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'start',
    header: () => <div className='text-xs text-left text-white'>Start</div>,
    cell: ({ row, table }) => {
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            if (table?.options?.meta?.startStopBot) {
              table?.options?.meta?.startStopBot(row.original.botID, true)
            }
          }}
        >
          <IoPlaySharp size={18} className={'text-primary cursor-pointer'} />
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'stop',
    header: () => <div className='text-xs text-left text-white'>Stop</div>,
    cell: ({ row, table }) => {
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            if (table?.options?.meta?.startStopBot) {
              table?.options?.meta?.startStopBot(row.original.botID, false)
            }
          }}
        >
          <IoStopSharp size={18} className={'text-primary cursor-pointer'} />
        </div>
      )
    },
    size: 50,
  },
  {
    accessorKey: 'edit',
    header: () => <div className='text-xs text-left text-white'>Edit</div>,
    cell: ({ row, table }) => {
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            if (table?.options?.meta?.editBot) {
              table?.options?.meta?.editBot(row.original.botID)
            }
          }}
        >
          <FiEdit size={18} className={'text-primary cursor-pointer'} />
        </div>
      )
    },
    size: 50,
  },
]
