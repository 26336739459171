import { forwardRef, ReactNode } from 'react'
import styled, { IStyledComponent } from 'styled-components'

import { layoutMixins } from '@/styles/layoutMixins'

type ElementProps = {
  analyticsConfig?: {
    event: string
    meta?: FixMe
  }
  children: ReactNode
  href?: string
  onClick?: (e: MouseEvent) => void
  withIcon?: boolean
}

type StyleProps = {
  className?: string
}

export const Link = forwardRef<HTMLAnchorElement, ElementProps & StyleProps>(
  (
    { analyticsConfig, children, className, href, onClick, withIcon = false, ...props }: ElementProps & StyleProps,
    ref,
  ) => (
    <Styled.A
      ref={ref}
      className={className}
      href={href}
      onClick={(e: MouseEvent) => {
        if (analyticsConfig) {
          console.log(analyticsConfig)
        }

        onClick?.(e)
      }}
      rel='noopener noreferrer'
      target='_blank'
      {...props}
    >
      {children}
      {withIcon && <h2>icon</h2>}
    </Styled.A>
  ),
)

const Styled: Record<string, IStyledComponent<'web', FixMe>> = {}

Styled.A = styled.a<StyleProps>`
  --link-color: inherit;
  color: var(--link-color);

  ${layoutMixins.spacedRow}
  gap: 0.25em;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: var(--link-color);
  }
`
