import { type TooltipStrings } from '@/constants/localization'

export const withdrawTooltips: TooltipStrings = {
  'fast-withdraw-fee': () => ({
    title: 'title_fast-withdraw-fee', //stringGetter({ key: TOOLTIP_STRING_KEYS.FAST_WITHDRAW_FEE_TITLE }),
    body: 'body_fast-withdraw-fee', //stringGetter({ key: TOOLTIP_STRING_KEYS.FAST_WITHDRAW_FEE_BODY }),
  }),
  'withdraw-types': () => ({
    title: 'title_withdraw-types', //stringGetter({ key: TOOLTIP_STRING_KEYS.WITHDRAW_TYPES_TITLE }),
    body: 'body_withdraw-types', //stringGetter({ key: TOOLTIP_STRING_KEYS.WITHDRAW_TYPES_BODY }),
  }),
} as const
