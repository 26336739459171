import { FormEvent, useState } from 'react'
import { Asset, AssetType, getSdk } from '@/graphql/generated/graphql-request'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAllAssets } from '@/hooks/useAsset'
import { removeLettersForInput } from '@/utils/inputUtils'
import { useMutation } from '@tanstack/react-query'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { queryClient } from '@/services/api'
import { removeFormatNumber } from '@/utils/numberUtils'

type Inputs = {
  amount: string
  asset: string
}

const validationSchema = z.object({
  amount: z.string().min(1, 'Please insert an amount'),
  asset: z.string().min(1, 'Please select an asset'),
})

type ValidationSchema = z.infer<typeof validationSchema>

export const CollateralizePanel = () => {
  const { allAssets } = useAllAssets()
  const assetsWithoutPools = allAssets.filter((asset: Asset) => asset?.type !== AssetType.PoolShare)
  const [selectedOption, setSelectedOption] = useState(assetsWithoutPools[0]?.symbol ?? '') // Initialize the selected option

  const collateralizeMutation = useMutation({
    mutationFn: ({ amount, assetID }: { amount: string; assetID: string }) =>
      getSdk(graphQLClient)
        .Collateralize({ asset: { amount, assetID } })
        .then(() => {
          const asset = allAssets.find((a) => a.uid === assetID)
          createNotification(
            'Asset Management & Operations ',
            {
              text: 'Collateralize was successful',
              amount: amount,
              asset: asset,
            },
            NotificationType.ASSET_OPERATIONS,
          )
        })
        .catch((err) => {
          const message = err?.message ?? 'Error on Collateralize'
          createNotification('Error on Collateralize', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['USER'] }).finally()
    },
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
  })

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value
    const sanitizedInput = removeLettersForInput(inputValue)
    setValue('amount', sanitizedInput)
  }

  const onCollateralize = async (data: Inputs) => {
    try {
      const { amount, asset } = data
      const unformattedAmount = removeFormatNumber(amount)

      const fullAsset = allAssets.find((a) => a.uid === asset)
      const decimalAmount = Number(unformattedAmount) * Math.pow(10, fullAsset?.decimals ?? 0)

      // Perform action for collateralize asset here
      collateralizeMutation.mutate({
        amount: decimalAmount.toString(),
        assetID: asset,
      })

      console.log('Collateralize', decimalAmount, asset)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='w-full max-w-md panel dark:bg-card flex flex-col flex-grow h-full'>
      <h1 className={'dark:text-white text-lg pb-1 font-semibold'}>Collateralize</h1>
      <div className='max-w-xs break-words text-sm'>This will Collateralize a specific asset amount</div>
      <form>
        {/* Dropdown for the selection field */}
        <div className={'mt-8'}>
          <div className='relative flex flex-col'>
            <div className='flex items-center'>
              <div className='py-1 min-w-[100px] text-left'>Asset</div>
              <select
                {...register('asset', { required: true })}
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
              >
                {assetsWithoutPools.map((asset: Asset) => (
                  <option key={'collateralize_option_' + asset.uid} value={asset?.uid}>
                    {asset?.symbol}
                  </option>
                ))}
              </select>
            </div>
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.asset ? errors.asset.message : '\u00a0'}
            </span>
          </div>
          <div className='relative flex flex-col'>
            <div className='flex justify-between items-center top-0'>
              <div className='py-1 min-w-[100px] text-left'>Amount</div>
              <input
                id={'amount'}
                {...register('amount', { required: true })}
                onInput={(e) => handleInputChange(e)}
                className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
              />
            </div>
            <span className='text-red-500 text-end w-full' style={{ minHeight: '1rem' }}>
              {errors.amount ? errors.amount.message : '\u00a0'}
            </span>
          </div>
        </div>
        <div className='mt-8 flex flex-row gap-2 '>
          <button
            type='button'
            onClick={handleSubmit(onCollateralize)}
            //disabled={!isValid || !isDirty}
            className='btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
          >
            Collateralize
          </button>
        </div>
      </form>
    </div>
  )
}
