interface GeneralInfoCardProps {
  title: string
  values: { [key: number]: number }
}

const InfoCard = ({ title, values }: GeneralInfoCardProps) => {
  return (
    <div className='w-full panel dark:bg-card p-0'>
      <div className='p-4 pt-2'>
        <h2 className='text-[#3b3f5c] text-xl font-semibold my-2 dark:text-white-light'>{title}</h2>
        {Object.entries(values).map(([key, value]) => (
          <div className='flex flex-row py-2 justify-between text-center items-center w-full' key={key}>
            <div className='text-sm font-semibold text-dark-light dark:text-white'>{key}</div>
            <div className='ml-1 text-dark text-sm font-semibold dark:text-white-dark'>{value}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default InfoCard
