// src/utils/UIutils.ts
export const initializeCustomScrollbar = () => {
  setTimeout(() => {
    const container = document.getElementById('main')
    if (container) {
      container.style.overflowX = 'scroll'
      container.style.overflowY = 'hidden'
      container.style.scrollbarWidth = 'none' // Hide the default scrollbar

      // Check if the custom scrollbar already exists
      let customScrollbar = document.getElementById('custom-scrollbar')
      if (!customScrollbar) {
        // Create a custom scrollbar
        customScrollbar = document.createElement('div')
        customScrollbar.style.position = 'fixed'
        customScrollbar.style.bottom = '0'
        customScrollbar.style.left = '0'
        customScrollbar.style.display = 'none' // Hide the scrollbar by default
        customScrollbar.style.height = '8px'
        customScrollbar.style.width = '100%'
        customScrollbar.style.background = '#111'
        customScrollbar.style.cursor = 'pointer'
        customScrollbar.id = 'custom-scrollbar'
        customScrollbar.style.marginBottom = '24px'
        document.body.appendChild(customScrollbar)

        // Create a thumb for the scrollbar
        const thumb = document.createElement('div')
        thumb.style.position = 'absolute'
        thumb.style.height = '100%'
        thumb.style.width = '90%' //initial width
        thumb.style.background = '#222'
        thumb.style.borderRadius = '1px'
        thumb.style.cursor = 'pointer'
        customScrollbar.appendChild(thumb)

        // Update thumb size and position based on scroll
        const updateThumb = () => {
          const visibleRatio = container.clientWidth / container.scrollWidth
          if (customScrollbar) {
            if (visibleRatio >= 1) {
              customScrollbar.style.display = 'none'
              return
            } else {
              customScrollbar.style.display = 'block'
            }
          }
          thumb.style.width = `${visibleRatio * 100}%`
          const scrollRatio = container.scrollLeft / (container.scrollWidth - container.clientWidth)
          if (customScrollbar) {
            thumb.style.left = `${scrollRatio * (customScrollbar.clientWidth - thumb.clientWidth)}px`
          }
        }

        window.addEventListener('load', updateThumb)
        window.addEventListener('loadeddata', updateThumb)
        window.addEventListener('loadstart', updateThumb)
        container.addEventListener('scroll', updateThumb)
        container.addEventListener('load', updateThumb)
        window.addEventListener('resize', updateThumb)

        customScrollbar.addEventListener('mousedown', function (e: MouseEvent) {
          const startX = e.pageX
          const startScrollLeft = container.scrollLeft

          function onMouseMove(e: MouseEvent) {
            const deltaX = e.pageX - startX
            if (container) {
              container.scrollLeft = startScrollLeft + deltaX
            }
          }

          function onMouseUp() {
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
          }

          document.addEventListener('mousemove', onMouseMove)
          document.addEventListener('mouseup', onMouseUp)
        })
      }
    }
  }, 500)
}
