import { combineReducers, configureStore } from '@reduxjs/toolkit'
import themeConfigSlice from './themeConfigSlice'
import chartSlice from '@/store/chartPriceSlice'
import notificationSlice from '@/store/notificationSlice'
import { persistReducer, persistStore } from 'redux-persist'

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants'
import candleSlice from '@/store/candleSlice'

//const history = createBrowserHistory()

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['notification', 'themeConfig', 'chart'],
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
}

let rehydrationComplete: FixMe
// let rehydrationFailed

const rehydrationPromise = new Promise((resolve) => {
  rehydrationComplete = resolve
  // rehydrationFailed = reject
})
export function rehydration() {
  return rehydrationPromise
}

const resetEnhancer = (rootReducer: FixMe) => (state: FixMe, action: FixMe) => {
  if (action.type !== 'LOGOUT_SUCCESS') return rootReducer(state, action)

  return rootReducer(undefined, {})
}

const createRootReducer = () =>
  combineReducers({
    themeConfig: themeConfigSlice,
    chart: chartSlice,
    notification: notificationSlice,
    candles: candleSlice,
  })

const persistedReducer = persistReducer(persistConfig, resetEnhancer(createRootReducer()))

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActionPaths: ['payload.content'],
      },
    }),
})

const persistor = persistStore(store, null, () => {
  // this will be invoked after rehydration is complete
  rehydrationComplete()
})

export { persistor, store }
export type IRootState = ReturnType<typeof persistedReducer>
