import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import { useAllAssets } from '@/hooks/useAsset'
import { useModulePositions } from '@/hooks/useModulePositions'
import { tableColumnsModulePositions } from '@/components/administration/TableColumnsModulePositions'
import { Balance } from '@/graphql/generated/graphql-request'

export type AggregatedModulePositions = {
  assetID: string
  balance: Balance
  supplied: Balance
  borrowed: Balance
  virtual: Balance
}

export function TableModulePositions() {
  const { modulePositions } = useModulePositions()
  const { allAssets } = useAllAssets()
  const columns = tableColumnsModulePositions

  // Step 1: Extract all assetIDs from modulePositions
  const assetIDs = new Set([
    ...modulePositions.balance.map((item) => item.assetID),
    ...modulePositions.supplied.map((item) => item.assetID),
    ...modulePositions.borrowed.map((item) => item.assetID),
    ...modulePositions.virtual.map((item) => item.assetID),
  ])

  // Step 2: Create an object to store the aggregated data for each assetID
  const aggregatedData: Record<string, any> = {}

  assetIDs.forEach((assetID) => {
    aggregatedData[assetID] = {
      assetID,
      balance: modulePositions.balance.find((item) => item.assetID === assetID) || {
        assetID,
        amount: '0',
        sortAmount: 0,
      },
      supplied: modulePositions.supplied.find((item) => item.assetID === assetID) || {
        assetID,
        amount: '0',
        sortAmount: 0,
      },
      borrowed: modulePositions.borrowed.find((item) => item.assetID === assetID) || {
        assetID,
        amount: '0',
        sortAmount: 0,
      },
      virtual: modulePositions.virtual.find((item) => item.assetID === assetID) || {
        assetID,
        amount: '0',
        sortAmount: 0,
      },
    }
  })

  // Step 3: Convert the object to an array of objects
  const aggregatedArray = Object.values(aggregatedData) as AggregatedModulePositions[]

  const getAsset = (assetID: string) => {
    return allAssets.find((asset) => asset.uid === assetID)
  }

  const table = useReactTable({
    data: aggregatedArray,
    columns,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      getAsset,
    },
  })

  return (
    <div className='w-full panel dark:bg-card flex flex-col h-full'>
      <div className='relative flex justify-between items-center'>
        <h1 className={'dark:text-white text-lg pb-1 font-semibold'}>Positions</h1>
      </div>
      <Table>
        <TableHeader className='text-sm'>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className='p-1 ' key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    className='dark:bg-dark-darker border-b border-b-dark'
                    key={header.id}
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow className='p-1' key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell className={`border-b border-dark`} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className='border p-1'>
              <TableCell colSpan={columns.length} className='text-center'>
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className='flex items-center justify-end space-x-2 py-4'>
        <Button variant='outline' size='sm' onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Previous
        </Button>
        <div className={'text-xs'}>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <Button variant='outline' size='sm' onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          Next
        </Button>
      </div>
    </div>
  )
}
