import { Balance } from '@/graphql/generated/graphql-request'

export type BalanceWithAvailability = Balance & { available: boolean }

export const groupBalancesByAssetID = (
  availableBalance: Balance[],
  lockedBalance: Balance[],
): Record<string, BalanceWithAvailability[]> => {
  const allBalances = [
    ...availableBalance.map((balance) => ({ ...balance, available: true })),
    ...lockedBalance.map((balance) => ({ ...balance, available: false })),
  ]
  const groupedBalances: Record<string, BalanceWithAvailability[]> = {}

  allBalances.forEach((balance) => {
    if (!groupedBalances[balance.assetID]) {
      groupedBalances[balance.assetID] = []
    }
    groupedBalances[balance.assetID].push(balance as BalanceWithAvailability)
  })

  return groupedBalances
}
