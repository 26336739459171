import { useQuery } from '@tanstack/react-query'
import { GetAllUserPositionsQueryVariables, getSdk } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { useQueryTime } from '@/hooks/useQueryTime'

export const useUserPosition = (params: GetAllUserPositionsQueryVariables) => {
  const {
    isLoading: loadingUserPosition,
    data: userPosition,
    status,
  } = useQuery({
    queryKey: ['USER_POSITION', params.userUid],
    queryFn: async () =>
      getSdk(graphQLClient)
        .GetAllUserPositions(params)
        .then((result) => result.getAllUserPositions)
        .catch((err) => {
          const message = err?.message ?? 'Error on GetAppEnv'
          createNotification('Error on GetAppEnv', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    placeholderData: {
      total: [],
      available: [],
      locked: [],
      supplied: [],
      borrowed: [],
    },
  })
  useQueryTime(`['USER_POSITION', ${params.userUid}]`, status)
  return {
    loadingUserPosition,
    userPosition: userPosition ?? {
      total: [],
      available: [],
      locked: [],
      supplied: [],
      borrowed: [],
    },
  }
}
