import { IoMdClose } from 'react-icons/io'
import { IoMenu } from 'react-icons/io5'

interface MenuIconProps {
  onClick: (isActive: boolean) => void
  isMobileMenuOpen: boolean
}

export const MenuIcon = ({ onClick, isMobileMenuOpen }: MenuIconProps) => {
  const setStatus = () => {
    onClick(!isMobileMenuOpen)
  }

  return (
    <div onClick={setStatus}>
      {isMobileMenuOpen ? (
        <IoMdClose size={48} />
      ) : (
        <div>
          <IoMenu size={48} />
        </div>
      )}
    </div>
  )
}
