import { type TooltipStrings } from '@/constants/localization'

export const generalTooltips: TooltipStrings = {
  'aggregated-candles': () => ({
    title: 'Aggregated Candles', //stringGetter({ key: TOOLTIP_STRING_KEYS.LEGACY_SIGNING_TITLE }),
    body: 'When this option is turned on, it will show only the last candle for each minute ', //stringGetter({ key: TOOLTIP_STRING_KEYS.LEGACY_SIGNING_BODY }),
  }),
  'legacy-signing': () => ({
    title: 'title_general1', //stringGetter({ key: TOOLTIP_STRING_KEYS.LEGACY_SIGNING_TITLE }),
    body: 'body_general1', //stringGetter({ key: TOOLTIP_STRING_KEYS.LEGACY_SIGNING_BODY }),
  }),
  'remember-me': () => ({
    title: 'title_general2', //stringGetter({ key: TOOLTIP_STRING_KEYS.REMEMBER_ME_TITLE }),
    body: 'body_general2', //stringGetter({ key: TOOLTIP_STRING_KEYS.REMEMBER_ME_BODY }),
  }),
} as const
