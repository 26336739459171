import { ColumnDef, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import { useAllAssets } from '@/hooks/useAsset'
import { tableColumnsModulePositions } from '@/components/administration/TableColumnsModulePositions'
import { Asset, AssetType, AssetUsage, getSdk } from '@/graphql/generated/graphql-request'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { formatNumber } from '@/utils/numberUtils'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { useMutation } from '@tanstack/react-query'
import { graphQLClient } from '@/services/graphql'
import { queryClient } from '@/services/api'
import { useEffect, useState } from 'react'

const tableColumnsAssets: ColumnDef<Asset>[] = [
  {
    accessorKey: 'asset',
    header: () => <div className='text-xs text-left text-white'>Asset</div>,
    cell: ({ row, table }) => {
      const symbol = row.original.symbol
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(symbol)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Asset ${symbol} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {symbol}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'description',
    header: () => <div className='text-xs text-left text-white'>Description</div>,
    cell: ({ row, table }) => {
      return <div className='border-0 text-left text-xs text-white-dark font-semibold'>{row.original.description}</div>
    },
    size: 300,
  },
  {
    accessorKey: 'bond',
    header: () => <div className='text-xs text-left text-white'>Bond</div>,
    cell: ({ row, table }) => {
      const bond = row.original.bond
      return (
        <div className='flex flex-col'>
          <div className='flex flex-col justify-between gap-1 items'>
            <div className='w-1/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Name</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>{bond?.name}</div>
            </div>
            <div className='w-2/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Coupon Start Date</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.couponStartDate}
              </div>
            </div>
            <div className='w-1/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Final Coupon Date</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.finalCouponDate}
              </div>
            </div>
            <div className='w-1/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Issue Date</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.issueDate}
              </div>
            </div>
            <div className='w-1/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Issuer</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.issuer}
              </div>
            </div>
            <div className='w-1/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Maturity Date</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.maturityDate}
              </div>
            </div>
            <div className='w-1/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Payments Per Year</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>
                {bond?.numberOfPaymentsPerYear}
              </div>
            </div>
            <div className='w-1/3 flex flex-row items-center justify-start gap-2'>
              <div className='text-primary items-start align-bottom font-semibold'>Yield</div>
              <div className='border-0 text-left align-bottom text-xs text-white-dark font-semibold'>{bond?.yield}</div>
            </div>
          </div>
        </div>
      )
    },
    size: 1500,
  },
  {
    accessorKey: 'collateralWeight',
    header: () => <div className='text-xs text-left text-white'>Collateral Weight</div>,
    cell: ({ row, table }) => {
      return (
        <div className='border-0 text-left text-xs text-white-dark font-semibold'>{row.original.collateralWeight}</div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'liquidationThreshold',
    header: () => <div className='text-xs text-left text-white'>Liquidation Threshold</div>,
    cell: ({ row, table }) => {
      return (
        <div className='border-0 text-left text-xs text-white-dark font-semibold'>
          {row.original.liquidationThreshold}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'maxSupply',
    header: () => <div className='text-xs text-left text-white'>Max Supply</div>,
    cell: ({ row, table }) => {
      return (
        <div className='border-0 text-left text-xs text-white-dark font-semibold'>
          {formatNumber(row.original.maxSupply, 0)}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'maxUtilization',
    header: () => <div className='text-xs text-left text-white'>Max Utilization</div>,
    cell: ({ row, table }) => {
      return (
        <div className='border-0 text-left text-xs text-white-dark font-semibold'>{row.original.maxUtilization}</div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'updateAsset',
    header: () => <div className='text-xs text-left text-white'>Update Asset</div>,
    cell: ({ row, table }) => {
      const uid = row.original.uid
      const [usages, setUsages] = useState<(AssetUsage | '')[]>(row.original.usage)
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild className={'border-0 bg-card hover:bg-card'}>
            <button
              type='button'
              className='w-[300px] bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
            >
              Update Asset
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent side='left' className='w-full'>
            <DropdownMenuLabel>
              <div className={'w-full ps-8 text-base text-primary'}>Update Asset</div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <div className='flex gap-4'>
              <div className='mx-8 my-4'>
                <div className='text-sm'>Usage</div>
                {usages.map((u, i) => (
                  <div key={u} className='flex gap-2 items-center mb-4'>
                    <select
                      className='update-asset-usage relative border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                      value={u}
                      onChange={(e) => setUsages((us) => us.map((s, j) => (i === j ? (e.target.value as any) : s)))}
                    >
                      {['', ...Object.values(AssetUsage).filter((s) => s === u || !usages.includes(s))].map((usage) => (
                        <option key={usage} value={usage}>
                          {usage}
                        </option>
                      ))}
                    </select>
                    <button onClick={() => setUsages((us) => us.filter((s, j) => i !== j))} className='text-red-500'>
                      X
                    </button>
                  </div>
                ))}
                <button className='w-full mb-2 text-primary' onClick={() => setUsages((u) => [...u, ''])}>
                  Add More
                </button>
                <div className='text-sm'>Collateral Weight</div>
                <input
                  id={'update-asset-collateral-weight'}
                  defaultValue={row.original.collateralWeight}
                  placeholder='Collateral Weight'
                  className='mb-4 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <div className='text-sm'>Liquidation Threshold</div>
                <input
                  id={'update-asset-liquidation-threshold'}
                  defaultValue={row.original.liquidationThreshold}
                  placeholder='Liquidation Threshold'
                  className='mb-4 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
              </div>
              <div className='mx-8 my-4'>
                <div className='text-sm'>Max Utilization</div>
                <input
                  id={'update-asset-max-utilization'}
                  defaultValue={row.original.maxUtilization}
                  placeholder='Max Utilization'
                  className='mb-4 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <div className='text-sm'>Max Supply</div>
                <input
                  id={'update-asset-max-supply'}
                  defaultValue={row.original.maxSupply}
                  placeholder='Max Supply'
                  className='mb-4 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
                <div className='text-sm'>Description</div>
                <textarea
                  id={'update-asset-description'}
                  defaultValue={row.original.description}
                  placeholder='Description'
                  className='mb-4 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                />
              </div>
            </div>
            <div className='flex gap-4 m-4 mt-0'>
              <button
                type='button'
                className='btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
                onClick={() => {
                  if (table?.options?.meta?.updateAsset) {
                    const usageEls = document.getElementsByClassName('update-asset-usage') || []
                    const usage: any = []
                    for (let i = 0; i < usageEls.length; i += 1) {
                      ;(usageEls[i] as any)?.value && usage.push((usageEls[i] as any)?.value)
                    }
                    table?.options?.meta?.updateAsset({
                      assetID: uid,
                      description: (document.getElementById('update-asset-description') as any)?.value || '',
                      usage: usage,
                      collateralWeight: (document.getElementById('update-asset-collateral-weight') as any)?.value || '',
                      liquidationThreshold:
                        (document.getElementById('update-asset-liquidation-threshold') as any)?.value || '',
                      maxUtilization: (document.getElementById('update-asset-max-utilization') as any)?.value || '',
                      maxSupply: (document.getElementById('update-asset-max-supply') as any)?.value || '',
                    })
                  }
                }}
              >
                Update
              </button>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
    size: 100,
  },
]

export function TableAssets() {
  const { allAssets } = useAllAssets()
  const columns = tableColumnsAssets

  const updateAssetMutation = useMutation({
    mutationFn: (params: {
      assetID: string
      description: string
      usage: [AssetUsage]
      collateralWeight: string
      liquidationThreshold: string
      maxUtilization: string
      maxSupply: string
    }) =>
      getSdk(graphQLClient)
        .UpdateAsset({
          updateAsset: params,
        })
        .then(() => {
          createNotification('Asset Update ', 'Asset update was successful', NotificationType.SIMPLE)
        })
        .catch((err) => {
          const message = err?.message ?? 'Error on UpdateAsset'
          createNotification('Error on UpdateAsset', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ASSETS'] }).finally()
    },
  })

  const table = useReactTable({
    data: allAssets,
    columns,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      updateAsset: (params) => updateAssetMutation.mutate(params),
    },
  })

  return (
    <div className='w-full panel dark:bg-card flex flex-col h-full'>
      <div className='relative flex justify-between items-center'>
        <h1 className={'dark:text-white text-lg pb-1 font-semibold'}>Assets</h1>
      </div>
      <Table>
        <TableHeader className='text-sm'>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className='p-1 ' key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    className='dark:bg-dark-darker border-b border-b-dark'
                    key={header.id}
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow className='p-1' key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell className={`border-b border-dark`} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className='border p-1'>
              <TableCell colSpan={columns.length} className='text-center'>
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className='flex items-center justify-end space-x-2 py-4'>
        <Button variant='outline' size='sm' onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Previous
        </Button>
        <div className={'text-xs'}>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <Button variant='outline' size='sm' onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          Next
        </Button>
      </div>
    </div>
  )
}
