import { clearAllCandles } from '@/store/candleSlice'
import { clearAll } from '@/store/chartPriceSlice'
import { clearNotifications } from '@/store/notificationSlice'
import { getAuth, signOut } from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { useDisconnect } from 'wagmi'
import { wsClient } from '@/services/graphql'

const useSignOutAndClearStore = () => {
  const { disconnectAsync } = useDisconnect()
  const dispatch = useDispatch()

  const signOutAndClearStore = async () => {
    await disconnectAsync()
    await signOut(getAuth())
    await wsClient.dispose()
    localStorage.clear()
    dispatch(clearNotifications())
    dispatch(clearAll())
    dispatch(clearAllCandles())
  }

  return signOutAndClearStore
}

export default useSignOutAndClearStore
