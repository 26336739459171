import { useTraderBots } from '@/hooks/useTraderBots'
import { useMemo } from 'react'

interface TraderBotCardProps {
  botID: string
}

export const TraderBotCard = ({ botID }: TraderBotCardProps) => {
  const { traderBots } = useTraderBots()
  const bot = useMemo(() => traderBots.find((bot) => bot.botID === botID), [traderBots, botID])

  if (!bot) {
    return <div>TraderBot not found</div>
  }

  return (
    <div className='shadow-md rounded-lg p-2 bg-transparent'>
      <h1 className='text-xl font-bold mb-4 text-primary'>Trader Bot</h1>
      <h1 className='text-xl font-bold mb-4'>{bot.name}</h1>
      <div className='flex flex-row justify-between'>
        <div>Order Book ID:</div>
        <div>{bot.orderBookID}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Strategy:</div>
        <div>{bot.strategyName}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Spread:</div>
        <div>{bot.spread}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Levels:</div>
        <div>{bot.levels}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Delta Levels:</div>
        <div>
          {bot.deltaLevels.map((deltaLevel, index) => (
            <div key={index} className='flex flex-row justify-between gap-2'>
              <div>Level: {deltaLevel.level}</div>
              <div>Delta: {deltaLevel.delta}</div>
            </div>
          ))}
        </div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Delta Mult:</div>
        <div>
          {bot.deltaMults.map((deltaMult, index) => (
            <div key={index} className='flex flex-row justify-between gap-2'>
              <div>Mult: {deltaMult.mult}</div>
              <div>Delta: {deltaMult.delta}</div>
            </div>
          ))}
        </div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Min Swap Amount:</div>
        <div>{bot.minSwapAmount}</div>
      </div>
      <div className='flex flex-row justify-between gap-4'>
        <div>Amount List:</div>
        <div>{bot.amountList.join(', ')}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Max Swap Amount:</div>
        <div>{bot.maxSwapAmount}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Order Quantity:</div>
        <div>{bot.orderQuantity}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Swap Quantity:</div>
        <div>{bot.swapQuantity}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Bid Execution Percentage:</div>
        <div>{bot.bidExePercentage}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Order Interval:</div>
        <div>{bot.orderInterval}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Min Delay Between Orders:</div>
        <div>{bot.minDelayBetweenOrders}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Min Take Percentage:</div>
        <div>{bot.minTakePercentage}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Max Take Percentage:</div>
        <div>{bot.maxTakePercentage}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Max Bid Percentage:</div>
        <div>{bot.maxBidPercentage}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>DPS:</div>
        <div>{bot.dps}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Min Skew:</div>
        <div>{bot.minSkew}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Max Skew:</div>
        <div>{bot.maxSkew}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Min Bid Price:</div>
        <div>{bot.minBidPrice}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Max Ask Price:</div>
        <div>{bot.maxAskPrice}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Tick Size:</div>
        <div>{bot.tickSize}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Amount Multiplier:</div>
        <div>{bot.amountMult}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Target Price:</div>
        <div>{bot.targetPrice}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Market Order File Path:</div>
        <div>{bot.marketOrderFilePath}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Created At:</div>
        <div>{new Date(bot.createdAt).toLocaleString()}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Updated At:</div>
        <div>{new Date(bot.updatedAt).toLocaleString()}</div>
      </div>
      <div className='flex flex-row justify-between'>
        <div>Updated By:</div>
        <div>{bot.updatedBy}</div>
      </div>
    </div>
  )
}
