import { createSlice } from '@reduxjs/toolkit'
import { NotificationItem } from '@/utils/notificationUtils'

// Step 2: Define the initial state
const initialState = {
  notifications: [] as NotificationItem[],
}

// Step 3: Create the slice
const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification(state, action: { payload: NotificationItem }) {
      //validate if notification with same id already exists, replace if it does or add if it doesn't
      const notification = action.payload
      const index = state.notifications.findIndex((n) => n.id === notification.id)
      if (index >= 0) {
        state.notifications[index] = notification
      } else {
        state.notifications.push(notification)
      }
    },
    deleteNotification(state, action: { payload: string }) {
      //validate if notification with same id already exists, replace if it does or add if it doesn't
      const id = action.payload
      const index = state.notifications.findIndex((n) => n.id === id)
      if (index >= 0) {
        state.notifications.splice(index, 1)
      }
    },
    clearNotifications(state) {
      state.notifications = []
    },
  },
})

// Step 4: Export the actions and reducer
export const { addNotification, deleteNotification, clearNotifications } = notificationSlice.actions
export default notificationSlice.reducer
