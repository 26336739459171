import { graphQLClient } from '@/services/graphql'
import { useQuery } from '@tanstack/react-query'
import { Asset, getSdk } from '@/graphql/generated/graphql-request'
import { useQueryTime } from '@/hooks/useQueryTime'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'

export const useAsset = (assetId: string) => {
  const {
    isLoading: loadingAsset,
    data: asset,
    status,
  } = useQuery({
    queryKey: ['ASSET', assetId],
    queryFn: async () =>
      getSdk(graphQLClient)
        .GetAsset({ assetId })
        .then((result) => result.getAsset)
        .catch((err) => {
          const message = err?.message ?? 'Error on GetAsset'
          createNotification('Error on GetAsset', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    enabled: !!assetId,
  })
  useQueryTime(`['ASSET', ${assetId}]`, status)
  return {
    loadingAsset,
    asset,
  }
}

export const useAllAssets = () => {
  const {
    isLoading: loadingAssets,
    data: allAssets,
    status,
  } = useQuery({
    queryKey: ['ASSETS'],
    queryFn: async () =>
      getSdk(graphQLClient)
        .GetAllAssets({
          pagination: {
            limit: 1000,
          },
        })
        .then((result) => result.getAllAssets as Asset[])
        .catch((err) => {
          const message = err?.message ?? 'Error on GetAllAssets'
          createNotification('Error on GetAllAssets', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    placeholderData: [],
  })
  useQueryTime(`['ASSETS']`, status)
  return {
    loadingAssets,
    allAssets: allAssets ?? [],
  }
}
