import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'

export const copyToken = () => {
  const token = localStorage?.getItem('firebaseToken') ?? ''

  if (!token || token.length === 0) {
    createNotification(
      'No Token Available',
      'There is no token saved in this session',
      NotificationType.SIMPLE,
      NotificationStatus.ERROR,
    )

    return
  }

  navigator.clipboard.writeText(token).finally(() => {
    const tokenSlice = token.slice(0, 8) + '...' + token.slice(-8)
    createNotification('Token', { token: tokenSlice }, NotificationType.TOKEN)
  })
}
